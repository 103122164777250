import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import TuneIcon from "@mui/icons-material/Tune";
import { Badge, Box, Chip, Paper, Popover, Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AlertElem from "../components/Alert";
import Event from "../components/event/Event";
import Post from "../components/post/Post";
import PostCreationModal from "../components/post/PostCreationModal";
import useScrollBottom from "../hooks/useScrollBottom";
import { fetchEvents, fetchMoreEvents } from "../redux/eventsSlice";
import {
  deletePost,
  editPost,
  fetchMoreMyPosts,
  fetchMorePosts,
  fetchMyPosts,
  fetchPosts,
  setAlertMessage,
} from "../redux/postsSlice";

import Utils from "../utils/Utils";

const { pathToFormattedDirectory } = Utils;

export default function Feeds() {
  const { t } = useTranslation(["posts-events", "common"]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [newPostModalOpened, setNewPostModalOpened] = useState(false);
  const { profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isMyPosts, setIsMyPosts] = useState(
    searchParams.get("my-posts") === "true"
  );
  const {
    status,
    posts: mainPosts,
    myPosts,
    hasMorePosts,
    hasMoreMyPosts,
    isAlertMessage,
    alertMessage,
    alertMessageType,
  } = useSelector((state) => state.posts);
  const {
    status: eventsStatus,
    hasMoreEvents,
    events,
  } = useSelector((state) => state.events);
  useEffect(() => {
    if (isMyPosts) dispatch(fetchMyPosts());
    else {
      dispatch(fetchPosts());
      dispatch(fetchEvents());
    }
  }, [dispatch, isMyPosts]);
  const [editingPost, setEditingPost] = useState({});
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    if (isMyPosts) setPosts(myPosts);
    else
      setPosts(
        [...mainPosts, ...events].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
  }, [events, mainPosts, isMyPosts, myPosts]);

  let list = profile.subscribedInterests;
  list = list.toSorted((a, b) => a.path.localeCompare(b.path));
  const [interests, setInterests] = useState(
    list.map((item) => ({ ...item, checked: true }))
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtered, setFiltered] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateInterests = (id) => {
    setInterests(
      interests.map((item) => {
        if (item._id === id) return { ...item, checked: !item.checked };
        return item;
      })
    );
  };
  const selectAll = () => {
    setInterests(interests.map((item) => ({ ...item, checked: true })));
  };
  const selectNone = () => {
    setInterests(interests.map((item) => ({ ...item, checked: false })));
  };
  const applyFilter = () => {
    handleClose();
    const filterInterests = interests.filter((item) => item.checked);
    // fetch posts and events
    dispatch(
      fetchPosts(
        filterInterests.map((item) => ({
          _id: item._id,
          path: item.path,
          location: item.location,
          range: item.range,
        }))
      )
    );
    dispatch(
      fetchEvents(
        filterInterests.map((item) => ({
          _id: item._id,
          path: item.path,
          location: item.location,
          range: item.range,
        }))
      )
    );
    if (list.length === filterInterests.length) return setFiltered(false);
    return setFiltered(true);
  };

  const filterOpened = Boolean(anchorEl);
  const filterId = filterOpened ? "filter-popover" : undefined;

  // more post fetching
  const { isNearBottom } = useScrollBottom();
  useEffect(() => {
    if (isMyPosts && isNearBottom && hasMoreMyPosts)
      dispatch(fetchMoreMyPosts());
    else if (isNearBottom && hasMorePosts) dispatch(fetchMorePosts());
    else if (isNearBottom && hasMoreEvents) dispatch(fetchMoreEvents());
  }, [
    isNearBottom,
    dispatch,
    hasMoreEvents,
    hasMorePosts,
    isMyPosts,
    hasMoreMyPosts,
  ]);

  const updateSearchParams = (is) => {
    searchParams.set("my-posts", is);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };
  return (
    <Box sx={{ flex: 1 }}>
      <Box
        sx={{
          background: theme.palette.background.default,
          position: "sticky",
          top: "4rem",
          zIndex: 5,
          boxShadow: `3rem 0 ${theme.palette.background.default}, -3rem 0 ${theme.palette.background.default}`,
        }}
      >
        <Box
          className="max-w-[600px] w-full mx-auto"
          sx={{
            mx: "auto",
            py: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Badge variant="dot" invisible={!filtered} color="secondary">
            <Button
              variant="outlined"
              color="inherit"
              sx={{ borderRadius: 10, color: theme.palette.text.primary }}
              startIcon={<TuneIcon />}
              onClick={handleClick}
              disabled={isMyPosts}
            >
              {t("common:filter")}
            </Button>
          </Badge>
          <Badge
            sx={{ mx: 0.5, ml: "auto" }}
            variant="dot"
            invisible={!isMyPosts}
            color="secondary"
          >
            <Button
              variant={isMyPosts ? "contained" : "outlined"}
              color="inherit"
              sx={{
                borderRadius: 10,
                color: theme.palette.text.primary,
              }}
              startIcon={<PersonIcon />}
              onClick={() => {
                setIsMyPosts(!isMyPosts);
                updateSearchParams(!isMyPosts);
              }}
            >
              {t("myPosts")}
            </Button>
          </Badge>
          <Button
            startIcon={<AddBoxOutlinedIcon />}
            variant="outlined"
            color="success"
            sx={{ borderRadius: 10, textWrap: "nowrap" }}
            onClick={() => setNewPostModalOpened(true)}
          >
            {t("newPost")}
          </Button>
          {filterOpened && (
            <Popover
              id={filterId}
              open={filterOpened}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleClose}
              disableRestoreFocus
            >
              <Box
                sx={{
                  px: 2,
                  borderRadius: 3,
                }}
              >
                <Box
                  sx={{
                    py: 2,
                    display: "flex",
                    flexFlow: "column nowrap",
                    textAlign: "left",
                    "& > *": {
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5">
                      {t("filterBySubscriptions")}
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ ml: "auto" }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {interests.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        my: 1,
                      }}
                    >
                      <Button
                        color="secondary"
                        variant="outlined"
                        sx={{ borderRadius: 5 }}
                        size="small"
                        onClick={() => selectAll()}
                      >
                        {t("common:selectAll")}
                      </Button>
                      <Button
                        color="warning"
                        variant="outlined"
                        sx={{ borderRadius: 5 }}
                        size="small"
                        onClick={() => selectNone()}
                      >
                        {t("common:selectNone")}
                      </Button>
                    </Box>
                  )}
                  {interests.map((item) => (
                    <Chip
                      key={item._id}
                      label={pathToFormattedDirectory(item.path)}
                      variant={item.checked ? "filled" : "outlined"}
                      onClick={() => updateInterests(item._id)}
                      sx={{ my: 0.5 }}
                    />
                  ))}
                  {interests.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <Button
                        color="success"
                        variant="contained"
                        sx={{ borderRadius: 5 }}
                        onClick={() => applyFilter()}
                      >
                        {t("common:apply")}
                      </Button>
                    </Box>
                  ) : (
                    <Typography variant="p" textAlign="center">
                      {t("common:noSubscriptions")}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Popover>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
        }}
        maxWidth={theme.breakpoints.values.sm}
        mx="auto"
      >
        <Box
          className="posts"
          sx={{ width: "100%", mx: "auto", position: "relative" }}
        >
          {posts.map((post, index) =>
            post.postType === "event" ? (
              <Event
                key={post._id}
                event={post}
                index={index}
                profile={profile}
                isAuthorAction={false}
                pageType='feeds'
              />
            ) : (
              <Post
                key={post._id}
                post={post}
                index={index}
                profile={profile}
                editPost={(p) => dispatch(editPost(p))}
                deletePost={(id) => dispatch(deletePost(id))}
                setNewPostModalOpened={setNewPostModalOpened}
                setEditingPost={setEditingPost}
                pageType='feeds'
              />
            )
          )}
          {status === "success" &&
            eventsStatus === "success" &&
            posts.length === 0 && (
              <Paper
                elevation={2}
                sx={{
                  my: 1,
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{ textAlign: "center", my: 1, py: 1 }}
                  variant="h6"
                >
                  {t("noPost")}
                </Typography>
              </Paper>
            )}
          {(status === "loading" || eventsStatus === "loading") && (
            <Paper
              elevation={2}
              sx={{
                padding: "1rem",
                my: 1,
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ my: 1 }}
                />
                <Box sx={{ display: "flex", flexFlow: "column" }}>
                  <Skeleton
                    variant="text"
                    width={150}
                    height={20}
                    sx={{ mt: 1, mx: 1 }}
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    height={10}
                    sx={{ mx: 1 }}
                  />
                </Box>
              </Box>
              <Skeleton variant="text" height={10} sx={{ my: 0.5 }} />
              <Skeleton
                variant="text"
                width="75%"
                height={10}
                sx={{ my: 0.5 }}
              />
              <Skeleton variant="rectangular" height={300} sx={{ my: 1 }} />
              <Box
                sx={{
                  mt: 0.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Skeleton variant="rounded" width="32%" height={20} />
                <Skeleton variant="rounded" width="32%" height={20} />
                <Skeleton variant="rounded" width="32%" height={20} />
              </Box>
            </Paper>
          )}
        </Box>
        <PostCreationModal
          oldPost={editingPost}
          editPost={editPost}
          setEditingPost={setEditingPost}
          profile={profile}
          newPostModalOpened={newPostModalOpened}
          setNewPostModalOpened={setNewPostModalOpened}
        />
        <AlertElem
          isAlertMessage={isAlertMessage}
          alertMessage={alertMessage}
          alertMessageType={alertMessageType}
          onClose={() =>
            dispatch(setAlertMessage({ show: false, message: "", type: "" }))
          }
        />
      </Box>
    </Box>
  );
}
