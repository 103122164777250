import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { resetPasswordRequest } from '../redux/authSlice';

export default function ResetPasswordRequest() {
    const { t } = useTranslation(['auth', 'common']);
    const [data, setData] = useState({ email: '' });
    const [error, setError] = useState({ email: '' });
    const [isEmailSent, setIsEmailSent] = useState(false);
    const dispatch = useDispatch();

    const { passwordResetStatus } = useSelector((state) => state.auth);

    useEffect(() => {
        if (
            data.email.length > 0 &&
            !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                data.email
            )
        )
            setError((prev) => ({ ...prev, email: t('invalidEmail') }));
        else setError((prev) => ({ ...prev, email: '' }));
    }, [data, t]);

    const handleChange = (e) =>
        setData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(resetPasswordRequest({ email: data.email })).then((result) => {
            if (result.error) toast.error(result.error.message);
            else {
                setIsEmailSent(true);
            }
        });
    };
    return (
        <Box alignSelf="center" className="w-full">
            <Paper
                className="max-w-[600px] w-full mx-auto"
                elevation={3}
                sx={{
                    padding: '2rem 1rem',
                    my: 2,
                    borderRadius: 3,
                }}
            >
                <Box className="mb-1 flex justify-center">
                    <img src="logo.png" alt="Logo" />
                </Box>
                <Typography variant="h4" my={3} textAlign="center">
                    {t('resetYourPassword')}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl
                        sx={{ width: '100%', my: 1, bgcolor: 'transparent' }}
                        variant="outlined"
                        required
                    >
                        <InputLabel htmlFor="email">{t('email')}</InputLabel>
                        <OutlinedInput
                            error={Boolean(error.email)}
                            id="email"
                            onChange={handleChange}
                            type="email"
                            label={t('email')}
                            name="email"
                        />
                        <FormHelperText error>{error.email}</FormHelperText>
                    </FormControl>
                    <Box className="flex flex-col">
                        <Button
                            disabled={data.email.trimStart().length === 0 || isEmailSent}
                            sx={{
                                margin: '1rem auto',
                                padding: '0.75rem 1.75rem',
                                borderRadius: '28px',
                                textTransform: 'none',
                            }}
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {passwordResetStatus === 'loading' ? (
                                <CircularProgress size={24} sx={{ color: '#FFF' }} />
                            ) : (
                                t('sendPasswordResetEmail')
                            )}
                        </Button>
                    </Box>
                    {isEmailSent && (
                        <Typography sx={{ textAlign: 'center' }}>
                            {t('passwordResetRequestSuccessMsg')}
                        </Typography>
                    )}
                </form>
            </Paper>
        </Box>
    );
}
