import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import { useSocket } from "../provider/SocketContext";
import { clearAlertMessage, loginUser } from "../redux/authSlice";
import { conversation } from "../redux/conversationSlice";

export default function Login() {
  const { disconnectSocket } = useSocket();
  const { t } = useTranslation(["auth", "common"]);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    isKeepMeLoggedIn: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    status,
    profile,
    isLoggedIn,
    alertMessage,
    isAlertMessage,
    alertMessageType,
  } = useSelector((state) => state.auth);
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser(loginData));
    setTimeout(() => {
      let userDetails = localStorage.getItem("user_details") || "{}";
      let userDetails_ = JSON.parse(userDetails);
      if (Object.keys(userDetails_).length > 0) {
        let userId = userDetails_._id;
        dispatch(conversation(userId));
      }
    }, 3000);
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (!profile.gender || !profile.yearOfBirth) {
        return navigate("/profile");
      }
      const { from } = location.state || { from: { pathname: "/" } };
      navigate(from);
    } else {
      disconnectSocket();
    }
  }, [isLoggedIn, profile, navigate, location]);
  return (
    <Box alignSelf="center" className="w-full">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        <Box className="mb-1 flex justify-center">
          <img className="max-w-[4rem]" src="logo.png" alt="Logo" />
        </Box>
        <Typography variant="h4" my={3} textAlign="center">
          {t("login")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            onChange={(e) =>
              setLoginData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }))
            }
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            label={t("enterEmail")}
            type="email"
            required
            name="email"
          />
          <FormControl
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            required
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("enterPassword")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) =>
                setLoginData((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }))
              }
              name="password"
              label={t("enterPassword")}
            />
          </FormControl>
          <Box sx={{ textAlign: "center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={loginData.isKeepMeLoggedIn}
                  onChange={() =>
                    setLoginData((prev) => ({
                      ...prev,
                      isKeepMeLoggedIn: !prev.isKeepMeLoggedIn,
                    }))
                  }
                />
              }
              label={t("keepMeLoggedInOnThisDevice")}
            />
          </Box>
          <Box className="flex flex-col">
            <Button
              disabled={
                loginData.email.trimStart().length === 0 ||
                loginData.password.trimStart().length === 0
              }
              sx={{
                margin: "0.5rem auto",
                padding: "0.75rem 1.75rem",
                borderRadius: "28px",
                textTransform: "none",
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              {status === "loading" ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }} />
              ) : (
                t("login")
              )}
            </Button>
            <Box className="text-center">
              <NavLink
                to="/reset-password-request"
                className="text-blue-600 p-2 hover:underline"
              >
                {t("forgotPassword")}
              </NavLink>
            </Box>
            <Divider textAlign="center">{t("common:or")}</Divider>
            <Box className="text-center">
              <NavLink
                to="/register"
                className="text-blue-600 p-2 hover:underline"
              >
                {t("createNewAccount")}
              </NavLink>
            </Box>
          </Box>
        </form>
      </Paper>
      <Alert
        isAlertMessage={isAlertMessage}
        alertMessage={alertMessage}
        alertMessageType={alertMessageType}
        onClose={() => dispatch(clearAlertMessage())}
      />
    </Box>
  );
}
