import { Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import Countdown from '../components/commons/Countdown';

export default function PasswordResetSuccess() {
    const navigate = useNavigate();
    const redirect = () => {
        navigate('/login');
    };
    const { t } = useTranslation(['auth', 'common']);
    return (
        <Box className="w-full" alignSelf="center">
            <Paper
                className="max-w-[600px] w-full mx-auto"
                elevation={3}
                sx={{
                    padding: '2rem 1rem',
                    my: 2,
                    borderRadius: 3,
                }}
            >
                <Box className="flex justify-center" marginBottom="1rem">
                    <img src="logo.png" alt="Logo" />
                </Box>
                <Typography
                    className="text-green-600"
                    variant="h4"
                    my={3}
                    color="success"
                    textAlign="center"
                >
                    {t('passwordResetSuccessful')}
                </Typography>
                <Box className="text-center">
                    <Countdown targetTime={10} onRedirect={redirect} />
                    <Typography className="py-1">{t('redirectingTo')}</Typography>
                    <NavLink to="/login">
                        <Button variant="contained">{t('login')}</Button>
                    </NavLink>
                </Box>
            </Paper>
        </Box>
    );
}
