import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Paper, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Zoom from "@mui/material/Zoom";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeSubscription } from "../redux/authSlice";
import Utils from "../utils/Utils";
import InterestSubscription from "./InterestSubscription";
import Loader from "./Loader";

const { pathSeparator } = Utils;

function Subscription({ isSub, setIsSub }) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.auth);
  const [isInterestSub, setIsInterestSub] = useState(false);
  const [editingSub, setEditingSub] = useState({});
  return (
    <Modal
      aria-labelledby="your-subscriptions"
      aria-describedby="View your subscriptions"
      open={isSub}
      onClose={() => setIsSub(false)}
      closeAfterTransition
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiPaper-root": {
          position: "unset",
          zIndex: 20000,
        },
      }}
    >
      <Zoom in={isSub}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            position: "absolute",
            top: 0,
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            minWidth: "280px",
          }}
        >
          <Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography variant="h4" component="h2">
                {t("subscriptions")}
              </Typography>
              <IconButton onClick={() => setIsSub(false)} sx={{ ml: "auto" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            {profile?.subscribedInterests?.map((interest) => (
              <Box
                key={interest._id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography mr={1.5}>
                  {interest.path
                    .split(pathSeparator)
                    .filter(Boolean)
                    .slice(-2)
                    .join(" / ")}
                </Typography>
                <IconButton
                  aria-label="edit"
                  onClick={async () => {
                    setEditingSub(interest);
                    setIsInterestSub(true);
                  }}
                  sx={{ ml: "auto" }}
                  color="info"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={async () => {
                    await dispatch(removeSubscription({ id: interest.id }));
                  }}
                  color="error"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            {isInterestSub && (
              <Suspense fallback={<Loader />}>
                <InterestSubscription
                  profile={profile}
                  interest={editingSub}
                  isInterestSub={isInterestSub}
                  setIsInterestSub={setIsInterestSub}
                  subscription={editingSub}
                />
              </Suspense>
            )}
          </Box>
        </Paper>
      </Zoom>
    </Modal>
  );
}

export default Subscription;
