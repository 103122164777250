import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, ImageList, ImageListItem, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserImages, toggleModal } from '../redux/userImagesSlice';

export default function UserAvatarModal() {
    const { t } = useTranslation(['profile-setting']);
    const { isModalShown, userId, status, userImages } = useSelector((state) => state.userImages);
    const dispatch = useDispatch();
    const theme = useTheme();

    const hideModal = () => {
        dispatch(toggleModal());
    };

    useEffect(() => {
        if (!isModalShown || !userId) return;

        dispatch(fetchUserImages(userId));
    }, [dispatch, userId, isModalShown]);
    return (
        <Modal
            aria-labelledby="user-info"
            aria-describedby="User info"
            open={isModalShown}
            onClose={hideModal}
            closeAfterTransition
            disableScrollLock
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 250,
                },
            }}
            sx={{
                overflowY: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                m: 2,
                '& .MuiPaper-root': {
                    position: 'unset',
                    zIndex: 1,
                    margin: 'auto',
                },
            }}
        >
            <Fade in={isModalShown}>
                <Paper
                    elevation={2}
                    sx={{
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        maxWidth: "600px",
                        width: "100%",
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 4,
                        p: 3,
                        minWidth: '280px',
                    }}
                >
                    {status === 'success' ? (
                        <>
                            <Box className="flex">
                                <IconButton
                                    onClick={hideModal}
                                    sx={{ ml: 'auto', alignSelf: 'flex-start' }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box className="my-1.5">
                                <Typography variant="h5">{t('profilePicture')}</Typography>
                                {userImages.profilePic?.url ? (
                                    <img
                                        className="rounded-md max-w-80"
                                        srcSet={userImages.profilePic.url}
                                        src={userImages.profilePic.url}
                                        alt={t('post')}
                                        loading="lazy"
                                    />
                                ) : (
                                    <Typography sx={{ textAlign: 'center' }}>
                                        {t('noUploads')}
                                    </Typography>
                                )}
                            </Box>
                            <Box className="my-1.5">
                                <Typography variant="h5">{t('profileUploads')}</Typography>
                                <ImageList variant="masonry" cols={2} gap={4}>
                                    {userImages?.images?.length > 0 &&
                                        userImages.images.map((img) => (
                                            <ImageListItem key={img.key}>
                                                <img
                                                    className="rounded-md"
                                                    srcSet={img.url}
                                                    src={img.url}
                                                    alt={t('post')}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        ))}
                                </ImageList>
                                {!userImages?.images?.length > 0 && (
                                    <Typography sx={{ textAlign: 'center' }}>
                                        {t('noUploads')}
                                    </Typography>
                                )}
                            </Box>
                        </>
                    ) : (
                        <>
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="rounded" width="50%" height="100px" />
                            <Skeleton variant="text" width="70%" />
                            <Box className="flex flex-row gap-1">
                                <Skeleton variant="rounded" width="48%" height="100px" />
                                <Skeleton variant="rounded" width="48%" height="100px" />
                            </Box>
                        </>
                    )}
                </Paper>
            </Fade>
        </Modal>
    );
}
