import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo from "../assets/logo.png";
import ModalMessage from "../components/commons/ModalMessage";
import { changeEmail } from "../redux/authSlice";

export default function ChangeEmail() {
  const { t } = useTranslation(["auth"]);
  const [data, setData] = useState({ email: "", newEmail: "", password: "" });
  const [isModalShown, setIsModalShown] = useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { changeEmailStatus } = useSelector((state) => state.auth);

  const handleChange = (e) =>
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.newEmail === data.email)
      return toast.error(t("newEmailMustBeDifferent"));
    if (data.password.length === 0) return toast.error(t("invalidPassword"));
    if (
      data.email.length === 0 ||
      data.newEmail.length === 0 ||
      !new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(data.email) ||
      !new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(data.newEmail)
    )
      return toast.error(t("invalidEmail"));
    dispatch(changeEmail({ ...data })).then((result) => {
      if (result.error) toast.error(result.error.message);
      else {
        setIsModalShown(true);
      }
    });
  };
  return (
    <Box alignSelf="center" className="w-full">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        <Box className="mb-1 flex justify-center">
          <img src={logo} alt="Logo" />
        </Box>
        <Typography variant="h4" my={3} textAlign="center">
          {t("changeYourEmail")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            onChange={handleChange}
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            label={t("enterOldEmail")}
            type="email"
            required
            name="email"
          />
          <TextField
            onChange={handleChange}
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            label={t("enterNewEmail")}
            type="email"
            required
            name="newEmail"
          />
          <FormControl
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            required
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {t("enterPassword")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
              name="password"
              onChange={handleChange}
              label={t("enterPassword")}
            />
          </FormControl>
          <Box className="flex flex-col">
            <Button
              sx={{
                margin: "1rem auto",
                padding: "0.75rem 1.75rem",
                borderRadius: "28px",
                textTransform: "none",
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              {changeEmailStatus === "loading" ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }} />
              ) : (
                t("updateEmail")
              )}
            </Button>
          </Box>
        </form>
      </Paper>
      <ModalMessage
        showModal={isModalShown}
        handleCloseModal={() => setIsModalShown(false)}
      >
        <h3 className="text-3xl my-2 text-green-500">{t("emailSent")}</h3>
        <p>{t("followEmailLink")}</p>
      </ModalMessage>
    </Box>
  );
}
