import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublicIcon from "@mui/icons-material/Public";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import {
  Box,
  Chip,
  ImageList,
  ImageListItem,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Utils from "../../utils/Utils";
import PostDirectory from "../PostDirectory";
import User from "../User";
import UserAvatar from "../UserAvatar";
import CommentSection from "./CommentSection";
import LinkPreview from "./LinkPreview";
import ReactionAndComments from "./ReactionAndComments";
import ReportPost from "./ReportPost";

const { postBorderColors, splitText, urlRegex } = Utils;

export default function Post({
  post,
  profile,
  deletePost,
  setNewPostModalOpened,
  setEditingPost,
  interactionDisabled,
  pageType,
}) {
  const { t, i18n } = useTranslation(["posts-events"]);
  const { language } = i18n;
  let links = post.description.match(urlRegex);
  links = links?.map((link) => {
    if (!link.startsWith("http")) {
      return `https://${link}`;
    }
    return link;
  });
  const segments = splitText(post.description);
  const [isCommentSectionOpened, setIsCommentSectionOpened] = useState(false);
  const [isReportPostModal, setIsReportPostModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editCurrentPost = () => {
    setEditingPost(post);
    setNewPostModalOpened(true);
  };

  return (
    <Paper
      key={post._id}
      elevation={2}
      sx={{
        padding: "1rem",
        my: 2.5,
        borderRadius: 3,
        boxShadow: `3px 3px 10px ${
          postBorderColors[post.isCommercial ? "commercial" : post.type]
        },-3px -3px 10px ${
          postBorderColors[post.isCommercial ? "commercial" : post.type]
        }`,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <UserAvatar
          userId={post.authorID}
          user={post.author}
          username={post.author.username}
        />
        <Box sx={{ display: "flex", flexFlow: "column" }}>
          <Box sx={{ display: "flex" }}>
            <User
              username={post.author.username}
              pageType={pageType}
              posts={post}
              showMessage={true}
            />
            {post.createdAt !== post.updatedAt && (
              <Chip size="small" label={t("edited")} />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ ml: 1, mr: 0.5 }} variant="p" fontSize="small">
              {DateTime.fromISO(post.createdAt)
                .setLocale(language)
                .toRelative()}
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              fontSize="small"
            >
              &#183;
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", ml: 0.5 }}
              fontSize="small"
            >
              {post.isPrivate ? (
                <LockIcon fontSize="0.5rem" />
              ) : (
                <PublicIcon fontSize="0.5rem" />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ ml: "auto" }}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {post.authorID === profile?._id && (
              <MenuItem
                onClick={() => {
                  editCurrentPost();
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t("editPost")}</Typography>
              </MenuItem>
            )}
            {post.authorID === profile?._id && (
              <MenuItem
                onClick={() => {
                  deletePost(post._id);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t("deletePost")}</Typography>
              </MenuItem>
            )}
            {profile?._id && (
              <MenuItem
                onClick={() => {
                  setIsReportPostModal((prev) => !prev);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <SmsFailedOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">{t("reportPost")}</Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
      <PostDirectory post={post} />
      <Typography sx={{ mb: 1, whiteSpace: "pre-wrap" }}>
        {segments.map((segment) =>
          segment.isLink ? (
            <Link
              key={segment.text}
              href={segment.url}
              target="_blank"
              sx={{ wordBreak: "break-all" }}
            >
              {segment.text}
            </Link>
          ) : (
            <Typography key={segment.text} component="span">
              {segment.text}
            </Typography>
          )
        )}
      </Typography>
      {links?.map((link) => (
        <LinkPreview key={link} url={link} />
      ))}
      <ImageList variant="masonry" cols={2} gap={4}>
        {post.images.map((img) => (
          <ImageListItem key={img.key}>
            <img
              className="rounded-md"
              srcSet={img.url}
              src={img.url}
              alt={t("post")}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <ReactionAndComments
        post={post}
        profile={profile}
        setIsCommentSectionOpened={setIsCommentSectionOpened}
        interactionDisabled={interactionDisabled}
      />
      {isCommentSectionOpened && (
        <CommentSection
          post={post}
          profile={profile}
          interactionDisabled={interactionDisabled}
        />
      )}
      {isReportPostModal && (
        <ReportPost
          profile={profile}
          post={post}
          isReportPostModal={isReportPostModal}
          setIsReportPostModal={setIsReportPostModal}
        />
      )}
    </Paper>
  );
}
