import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { search } from "../redux/nodesSlice";
import Utils from "../utils/Utils";
import Loader from "./Loader";

const minCharsToSearch = 3;
const { stringIncludesCaseInsensitive } = Utils;

function SearchBox({
  parent,
  updateParent,
  setAlertModalContent,
  setDeepParent,
  t,
}) {
  const { searchStatus, searchResult } = useSelector((state) => state.nodes);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (searchKey.length >= minCharsToSearch) dispatch(search(searchKey));
    else if (parent.children)
      setResults(
        parent.children
          .filter(
            (child) =>
              child.searchable &&
              stringIncludesCaseInsensitive(child.name, searchKey)
          )
          .map((item) => ({ ...item, isLocal: true }))
      );
  }, [searchKey, dispatch, parent]);

  useEffect(() => {
    if (searchResult.length > 0) setResults(searchResult);
  }, [searchResult]);

  return (
    <div className="search-box">
      <TextField
        id="search"
        label={t("searchInterest")}
        variant="outlined"
        sx={{ width: "100%" }}
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Box className={`wrapper ${isFocused && "expanded"}`}>
        <Box className="results">
          {searchStatus === "loading" ? (
            <Loader />
          ) : (
            results.map((item) => (
              <Button
                key={item.name}
                variant="outlined"
                color="inherit"
                sx={{ margin: 0.5, borderRadius: 5 }}
                onMouseDown={() => {
                  setDeepParent({
                    pathToParent: item.path,
                    isLocal: item.isLocal,
                    newParentID: item._id,
                  });
                  setSearchKey("");
                }}
              >
                <Typography>{item.name}</Typography>
              </Button>
            ))
          )}
        </Box>
      </Box>
    </div>
  );
}

export default SearchBox;
