import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import Utils from '../../utils/Utils';

const { serverURL } = Utils;
const maxDescriptionChars = 200;

export default function LinkPreview({ url }) {
    const [isLoading, setIsLoading] = useState(false);
    const [metadata, setMetadata] = useState(null);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const res = await fetch(`${serverURL}/link-preview`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ url }),
            });
            if (!res.ok) {
                console.error('Link preview failed.');
                setMetadata(null);
            }
            setMetadata(await res.json());
            setIsLoading(false);
        })();
    }, [url]);
    return isLoading ? (
        <Skeleton height={100} sx={{ my: -1 }} />
    ) : (
        <Link
            href={url}
            underline="none"
            target="_blank"
            sx={{ cursor: 'pointer', userSelect: 'none' }}
        >
            <Paper elevation={1} sx={{ my: 1, borderRadius: 1.5, overflow: 'hidden' }}>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{
                            maxWidth: '100px',
                            p: 0,
                            '& img': {
                                maxWidth: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            },
                        }}
                    >
                        {metadata?.image && <img src={metadata?.image} alt={metadata?.title} />}
                    </Box>
                    <Box ml={1} my={1}>
                        {!metadata?.title || !metadata?.description || !metadata?.hostname ? (
                            <Typography color="primary">{url}</Typography>
                        ) : (
                            <>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    {metadata?.title}
                                </Typography>
                                <Typography>
                                    {`${metadata?.description?.slice(0, maxDescriptionChars)}...`}
                                </Typography>
                                <Typography color="primary">{metadata?.hostname}</Typography>
                            </>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Link>
    );
}
