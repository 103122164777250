import {createSlice } from "@reduxjs/toolkit";

const initialState = {
    liveUsers: [],
};


export const liveUserSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setliveUsers: (state, action) => {
        state.liveUsers = action.payload.liveUsers;
    },
  },
});

export default liveUserSlice.reducer;
export const { setliveUsers } = liveUserSlice.actions;
