import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useScrollBottom from "../../hooks/useScrollBottom";
import {
  deleteEvent,
  editEvent,
  fetchMoreMyEvents,
  fetchMyEvents,
} from "../../redux/eventsSlice";
import Event from "./Event";

function MyEvents({ profile, setNewEventModalOpened, setEditingEvent }) {
  const { t } = useTranslation(["posts-events"]);
  const dispatch = useDispatch();
  const { isNearBottom } = useScrollBottom();
  const {
    status,
    hasMoreMyEvents,
    myEvents: events,
  } = useSelector((state) => state.events);
  useEffect(() => {
    if (isNearBottom && hasMoreMyEvents) dispatch(fetchMoreMyEvents());
  }, [isNearBottom, dispatch, hasMoreMyEvents]);
  useEffect(() => {
    dispatch(fetchMyEvents());
  }, [dispatch]);
  return (
    <Box>
      <Paper
        elevation={2}
        sx={{
          padding: "1rem",
          my: 1,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
              }}
            />
            <Typography ml={1}>{t("createNewEvent")}</Typography>
          </Box>
          <Button
            startIcon={<AddBoxOutlinedIcon />}
            variant="outlined"
            color="success"
            sx={{ borderRadius: 10, textWrap: "nowrap" }}
            onClick={() => setNewEventModalOpened(true)}
          >
            {t("createNew")}
          </Button>
        </Box>
      </Paper>
      {status === "success" &&
        events.findIndex((e) => e.authorID === profile._id) < 0 && (
          <Paper
            elevation={2}
            sx={{
              my: 1,
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              flexFlow: "column",
            }}
          >
            <Typography sx={{ textAlign: "center", my: 1, py: 1 }} variant="h6">
              {t("youHaveNoEvents")}
            </Typography>
            <Button
              startIcon={<AddBoxOutlinedIcon />}
              variant="outlined"
              color="success"
              sx={{ borderRadius: 10, textWrap: "nowrap", my: 2, mx: "auto" }}
              onClick={() => setNewEventModalOpened(true)}
            >
              {t("createNew")}
            </Button>
          </Paper>
        )}
      {events.map((event, index) => (
        <Event
          key={event._id}
          event={event}
          index={index}
          profile={profile}
          editEvent={(p) => dispatch(editEvent(p))}
          deleteEvent={(id) => dispatch(deleteEvent(id))}
          setNewEventModalOpened={setNewEventModalOpened}
          setEditingEvent={setEditingEvent}
        />
      ))}
      {status === "loading" && (
        <Paper
          elevation={2}
          sx={{
            padding: "1rem",
            my: 1,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ my: 1 }}
            />
            <Box sx={{ display: "flex", flexFlow: "column" }}>
              <Skeleton
                variant="text"
                width={150}
                height={20}
                sx={{ mt: 1, mx: 1 }}
              />
              <Skeleton variant="text" width={100} height={10} sx={{ mx: 1 }} />
            </Box>
          </Box>
          <Skeleton variant="text" height={10} sx={{ my: 0.5 }} />
          <Skeleton variant="text" width="75%" height={10} sx={{ my: 0.5 }} />
          <Skeleton variant="rectangular" height={300} sx={{ my: 1 }} />
          <Box
            sx={{ mt: 0.5, display: "flex", justifyContent: "space-between" }}
          >
            <Skeleton variant="rounded" width="32%" height={20} />
            <Skeleton variant="rounded" width="32%" height={20} />
            <Skeleton variant="rounded" width="32%" height={20} />
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default MyEvents;
