import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from "../i18n";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
  status: "idle",
  newPostStatus: "idle",
  posts: [],
  myPosts: [],
  hasMorePosts: true,
  hasMoreMyPosts: true,
  isAlertMessage: false,
  alertMessage: "",
  alertMessageType: "",
};

export const createNew = createAsyncThunk("posts/createNew", async (post) => {
  const response = await fetch(`${serverURL}/post/new`, {
    method: "POST",
    credentials: "include",
    body: post,
  });
  if (!response.ok) throw new Error(await response.text());
  const newPost = await response.json();
  return newPost;
});
export const editPost = createAsyncThunk("posts/editPost", async (post) => {
  const response = await fetch(`${serverURL}/post/edit`, {
    method: "POST",
    credentials: "include",
    body: post,
  });
  if (!response.ok) throw new Error(await response.text());
  const editedPost = await response.json();
  const loc = editedPost.location;
  editedPost.location = { lat: loc.coordinates[1], lng: loc.coordinates[0] };
  return editedPost;
});
export const deletePost = createAsyncThunk("posts/deletePost", async (id) => {
  const response = await fetch(`${serverURL}/post/delete`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ id }),
  });
  if (!response.ok) throw new Error(await response.text());
  return id;
});
export const fetchPosts = createAsyncThunk(
  "posts/fetchPosts",
  async (filter) => {
    const response = await fetch(`${serverURL}/post/posts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter }),
    });
    if (!response.ok) throw new Error(await response.text());
    const posts = await response.json();
    return posts.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const fetchMorePosts = createAsyncThunk(
  "posts/fetchMorePosts",
  async (filter, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await fetch(`${serverURL}/post/posts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter, skip: state.posts.posts.length }),
    });
    if (!response.ok) throw new Error(await response.text());
    const posts = await response.json();
    return posts.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const fetchMyPosts = createAsyncThunk("posts/fetchMyPosts", async () => {
  const response = await fetch(`${serverURL}/post/my-posts`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) throw new Error(await response.text());
  const posts = await response.json();
  return posts.map((post) => {
    const loc = post.location;
    return {
      ...post,
      location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
    };
  });
});
export const fetchMoreMyPosts = createAsyncThunk(
  "posts/fetchMoreMyPosts",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await fetch(`${serverURL}/post/my-posts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ skip: state.posts.myPosts.length }),
    });
    if (!response.ok) throw new Error(await response.text());
    const posts = await response.json();
    return posts.map((post) => {
      const loc = post.location;
      return {
        ...post,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const addReaction = createAsyncThunk(
  "posts/addReaction",
  async (reaction) => {
    const response = await fetch(`${serverURL}/post/react`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reaction),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const newReaction = await response.json();
    return newReaction;
  }
);
export const reportPost = createAsyncThunk(
  "posts/reportPost",
  async (report) => {
    const response = await fetch(`${serverURL}/post/report`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(report),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const reportDetails = await response.json();
    return reportDetails;
  }
);

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload.message;
      state.alertMessageType = action.payload.type;
      state.isAlertMessage = action.payload.show;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNew.pending, (state) => {
      state.newPostStatus = "loading";
    });
    builder.addCase(createNew.fulfilled, (state, action) => {
      state.posts = [action.payload, ...state.posts];
      state.myPosts = [action.payload, ...state.myPosts];
      state.newPostStatus = "success";
    });
    builder.addCase(createNew.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToPost")} ${action.error.message}`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.newPostStatus = "failed";
    });
    builder.addCase(editPost.fulfilled, (state, action) => {
      const editedPost = action.payload;
      const indexInPosts = state.posts.findIndex(
        (post) => post._id === editedPost._id
      );
      if (indexInPosts !== -1) {
        state.posts[indexInPosts] = editedPost;
      }
      const indexInMyPosts = state.myPosts.findIndex(
        (post) => post._id === editedPost._id
      );
      if (indexInMyPosts !== -1) {
        state.myPosts[indexInMyPosts] = editedPost;
      }
      toast.success(i18n.t("updateSuccessful"));
    });
    builder.addCase(editPost.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToEditPost")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.posts = state.posts.filter((p) => p._id !== action.payload);
      state.myPosts = state.myPosts.filter((p) => p._id !== action.payload);
    });
    builder.addCase(deletePost.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToDeletePost")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(fetchPosts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.posts = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchPosts.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchPosts")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(fetchMorePosts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchMorePosts.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.hasMorePosts = false;
        state.status = "success";
        return;
      }
      state.posts = [...state.posts, ...action.payload];
      state.status = "success";
    });
    builder.addCase(fetchMorePosts.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchMorePosts")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(fetchMyPosts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchMyPosts.fulfilled, (state, action) => {
      state.myPosts = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchMyPosts.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchPosts")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(fetchMoreMyPosts.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchMoreMyPosts.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.hasMoreMyPosts = false;
        state.status = "success";
        return;
      }
      state.myPosts = [...state.myPosts, ...action.payload];
      state.status = "success";
    });
    builder.addCase(fetchMoreMyPosts.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchMorePosts")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(addReaction.fulfilled, (state, action) => {
      const { postID, type } = action.payload;
      const post = state.posts.find((p) => p._id === postID);
      if (post) {
        if (!post.userReaction && type) post.reactionCount += 1;
        else if (post.userReaction && !type) post.reactionCount -= 1;
        post.userReaction = type;
      }
      const myPost = state.myPosts.find((p) => p._id === postID);
      if (myPost) {
        if (!myPost.userReaction && type) myPost.reactionCount += 1;
        else if (myPost.userReaction && !type) myPost.reactionCount -= 1;
        myPost.userReaction = type;
      }
    });
    builder.addCase(addReaction.rejected, (state, action) => {
      state.isAlertMessage = true;
      state.alertMessage = `${i18n.t("failedToReact")} ${action.error.message}`;
      state.alertMessageType = "error";
    });
    builder.addCase(reportPost.fulfilled, (state) => {
      state.isAlertMessage = true;
      state.alertMessage = i18n.t("successfullyReported");
      state.alertMessageType = "success";
    });
    builder.addCase(reportPost.rejected, (state, action) => {
      state.isAlertMessage = true;
      state.alertMessage = `${i18n.t("reportFailed")} ${action.error.message}`;
      state.alertMessageType = "error";
    });
  },
});

export default postsSlice.reducer;
export const { setAlertMessage } = postsSlice.actions;
