import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { LoadScript } from "@react-google-maps/api";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import EmailVerifyWarning from "./components/commons/EmailVerifyWarning";
import Contents from "./components/Contents";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import Navbar from "./components/Navbar";
import UserAvatarModal from "./components/UserAvatarModal";
import usePreferredTheme from "./hooks/usePreferredTheme";
import { checkAuth } from "./redux/authSlice";

const googleMapAPIKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

function App() {
  const { i18n } = useTranslation();
  // check auth when app first loads
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);
  const deviceTheme = usePreferredTheme();
  const [mode, setMode] = useState(deviceTheme);
  useEffect(() => {
    setMode(deviceTheme);
    if (deviceTheme === "dark") document.documentElement.classList.add("dark");
    if (deviceTheme === "light")
      document.documentElement.classList.remove("dark");
  }, [deviceTheme]);
  const { profile, isLoggedIn } = useSelector((state) => state.auth);

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: '"Manrope", "Helvetica", "Arial", sans-serif',
          button: {
            textTransform: "unset",
          },
        },
        palette: {
          mode,
          background: {
            paper: mode === "light" ? "#f0f2f5" : "#242526",
          },
        },
        components: {
          MuiAvatar: {
            defaultProps: {
              variant: "square",
            },
            styleOverrides: {
              root: {
                borderRadius: 7,
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          minHeight: "100dvh",
          bgcolor: theme.palette.background.default,
        }}
      >
        <LoadScript
          googleMapsApiKey={googleMapAPIKey}
          language={i18n.language}
          loadingElement={<Loader />}
        >
          <Router>
            <Suspense fallback={<Loader />}>
              {isLoggedIn && profile.email && !profile.emailVerified && (
                <EmailVerifyWarning userEmail={profile.email} />
              )}
            </Suspense>
            <Navbar mode={mode} setMode={setMode} />
            <Suspense fallback={<Loader />}>
              <Contents />
            </Suspense>
            {!isLoggedIn && <Footer mode={mode} setMode={setMode} />}
          </Router>
          <ToastContainer position="bottom-left" theme={mode} />
          <UserAvatarModal />
        </LoadScript>
      </Box>
    </ThemeProvider>
  );
}

export default App;
