import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from "../i18n";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
  status: "idle",
  newEventStatus: "idle",
  events: [],
  myEvents: [],
  hasMoreEvents: true,
  hasMoreMyEvents: true,
  commitmentStatus: "idle",
  isAlertMessage: false,
  alertMessage: "",
  alertMessageType: "",
};

export const createNew = createAsyncThunk("events/createNew", async (event) => {
  const response = await fetch(`${serverURL}/event/new`, {
    method: "POST",
    credentials: "include",
    body: event,
  });
  if (!response.ok) throw new Error(await response.text());
  const newEvent = await response.json();
  return newEvent;
});
export const editEvent = createAsyncThunk("events/editEvent", async (event) => {
  const response = await fetch(`${serverURL}/event/edit`, {
    method: "POST",
    credentials: "include",
    body: event,
  });
  if (!response.ok) throw new Error(await response.text());
  const editedEvent = await response.json();
  const loc = editedEvent.location;
  editedEvent.location = { lat: loc.coordinates[1], lng: loc.coordinates[0] };
  return editedEvent;
});
export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (id) => {
    const response = await fetch(`${serverURL}/event/delete`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) throw new Error(await response.text());
    return id;
  }
);
export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (filter) => {
    const response = await fetch(`${serverURL}/event/events`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter }),
    });
    if (!response.ok) throw new Error(await response.text());
    const events = await response.json();
    return events.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const fetchMoreEvents = createAsyncThunk(
  "events/fetchMoreEvents",
  async (filter, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await fetch(`${serverURL}/event/events`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter, skip: state.events.events.length }),
    });
    if (!response.ok) throw new Error(await response.text());
    const events = await response.json();
    return events.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const fetchMyEvents = createAsyncThunk(
  "events/fetchMyEvents",
  async () => {
    const response = await fetch(`${serverURL}/event/my-events`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) throw new Error(await response.text());
    const events = await response.json();
    return events.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const fetchMoreMyEvents = createAsyncThunk(
  "events/fetchMoreMyEvents",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await fetch(`${serverURL}/event/my-events`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ skip: state.events.myEvents.length }),
    });
    if (!response.ok) throw new Error(await response.text());
    const events = await response.json();
    return events.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const addReaction = createAsyncThunk(
  "events/addReaction",
  async (reaction) => {
    const response = await fetch(`${serverURL}/event/react`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reaction),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const newReaction = await response.json();
    return newReaction;
  }
);
export const reportEvent = createAsyncThunk(
  "events/reportEvent",
  async (report) => {
    const response = await fetch(`${serverURL}/event/report`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(report),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const reportDetails = await response.json();
    return reportDetails;
  }
);
export const addCommitment = createAsyncThunk(
  "events/addCommitment",
  async (commitment) => {
    const response = await fetch(`${serverURL}/event/commitment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(commitment),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload.message;
      state.alertMessageType = action.payload.type;
      state.isAlertMessage = action.payload.show;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNew.pending, (state) => {
      state.newEventStatus = "loading";
    });
    builder.addCase(createNew.fulfilled, (state, action) => {
      state.events = [action.payload, ...state.events];
      state.myEvents = [action.payload, ...state.myEvents];
      state.newEventStatus = "success";
    });
    builder.addCase(createNew.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToCreateEvent")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.newEventStatus = "failed";
    });
    builder.addCase(editEvent.fulfilled, (state, action) => {
      const editedEvent = action.payload;
      const index = state.events.findIndex(
        (post) => post._id === editedEvent._id
      );
      if (index !== -1) {
        state.events[index] = editedEvent;
      }
      const index2 = state.myEvents.findIndex(
        (post) => post._id === editedEvent._id
      );
      if (index2 !== -1) {
        state.myEvents[index2] = editedEvent;
      }
      toast.success(i18n.t("updateSuccessful"));
    });
    builder.addCase(editEvent.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToEditEvent")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      state.events = state.events.filter((p) => p._id !== action.payload);
      state.myEvents = state.myEvents.filter((p) => p._id !== action.payload);
    });
    builder.addCase(deleteEvent.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToDeleteEvent")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(fetchEvents.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchEvents")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(fetchMoreEvents.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchMoreEvents.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.hasMoreEvents = false;
        state.status = "success";
        return;
      }
      state.events = [...state.events, ...action.payload];
      state.status = "success";
    });
    builder.addCase(fetchMoreEvents.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchMoreEvents")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(fetchMyEvents.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchMyEvents.fulfilled, (state, action) => {
      state.myEvents = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchMyEvents.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchEvents")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(fetchMoreMyEvents.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchMoreMyEvents.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.hasMoreMyEvents = false;
        state.status = "success";
        return;
      }
      state.myEvents = [...state.myEvents, ...action.payload];
      state.status = "success";
    });
    builder.addCase(fetchMoreMyEvents.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchMoreEvents")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(addReaction.fulfilled, (state, action) => {
      const { eventID, type } = action.payload;
      const event = state.events.find((p) => p._id === eventID);
      if (event) {
        if (!event.userReaction && type) event.reactionCount += 1;
        else if (event.userReaction && !type) event.reactionCount -= 1;
        event.userReaction = type;
      }
      const myEvent = state.myEvents.find((p) => p._id === eventID);
      if (myEvent) {
        if (!myEvent.userReaction && type) myEvent.reactionCount += 1;
        else if (myEvent.userReaction && !type) myEvent.reactionCount -= 1;
        myEvent.userReaction = type;
      }
    });
    builder.addCase(addReaction.rejected, (state, action) => {
      state.isAlertMessage = true;
      state.alertMessage = `${i18n.t("failedToReact")} ${action.error.message}`;
      state.alertMessageType = "error";
    });
    builder.addCase(reportEvent.fulfilled, (state) => {
      state.isAlertMessage = true;
      state.alertMessage = i18n.t("successfullyReported");
      state.alertMessageType = "success";
    });
    builder.addCase(reportEvent.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("reportFailed")} ${action.error.message}`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(addCommitment.pending, (state) => {
      state.commitmentStatus = "loading";
    });
    builder.addCase(addCommitment.fulfilled, (state, action) => {
      const { commitment, commitmentsCount } = action.payload;
      const eventIndex = state.events.findIndex(
        (e) => e._id === commitment.eventID
      );
      if (eventIndex > -1) {
        const event = { ...state.events[eventIndex] };
        event.userCommitment = commitment.type;
        event.commitmentsCount = commitmentsCount;
        state.events[eventIndex] = event;
      }
      const myEventIndex = state.myEvents.findIndex(
        (e) => e._id === commitment.eventID
      );
      if (myEventIndex > -1) {
        const myEvent = { ...state.myEvents[myEventIndex] };
        myEvent.userCommitment = commitment.type;
        myEvent.commitmentsCount = commitmentsCount;
        state.myEvents[myEventIndex] = myEvent;
      }
      state.commitmentStatus = "success";
    });
    builder.addCase(addCommitment.rejected, (state, action) => {
      state.commitmentStatus = "failed";
      state.isAlertMessage = true;
      state.alertMessage = `${i18n.t("commitmentFailed")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
    });
  },
});

export default eventsSlice.reducer;
export const { setAlertMessage } = eventsSlice.actions;
