import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
  eventComments: {},
  isAlertMessage: false,
  alertMessage: "",
  alertMessageType: "",
};

export const newComment = createAsyncThunk(
  "eventComments/newComment",
  async (comment) => {
    const response = await fetch(`${serverURL}/event/comment/new`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });
    if (!response.ok) throw new Error(await response.text());
    const createdComment = await response.json();
    return createdComment;
  }
);
export const deleteComment = createAsyncThunk(
  "eventComments/deleteComment",
  async (comment) => {
    const response = await fetch(`${serverURL}/event/comment/delete`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    });
    if (!response.ok) throw new Error(await response.text());
    return comment;
  }
);
export const fetchComments = createAsyncThunk(
  "eventComments/fetchComments",
  async ({ eventID }) => {
    const response = await fetch(`${serverURL}/event/comments`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ eventID }),
    });
    if (!response.ok) throw new Error(await response.text());
    const comments = await response.json();
    return { comments, eventID };
  }
);
export const addCommentReaction = createAsyncThunk(
  "eventComments/addCommentReaction",
  async ({ comment, type, userID }) => {
    const reaction = {
      commentID: comment._id,
      type,
      userID,
    };
    const response = await fetch(`${serverURL}/event/comment/react`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reaction),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    return { type, comment };
  }
);

export const commentsSlice = createSlice({
  name: "eventsComments",
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload.message;
      state.alertMessageType = action.payload.type;
      state.isAlertMessage = action.payload.show;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(newComment.fulfilled, (state, action) => {
      const prevComments = state.eventComments[action.payload.eventID].comments;
      if (action.payload.replyTo) {
        const replyingComment = state.eventComments[
          action.payload.eventID
        ].comments.find((c) => c._id === action.payload.replyTo);
        if (replyingComment.hasOwnProperty("replies")) {
          replyingComment.replies = [action.payload].concat(
            replyingComment.replies
          );
        } else {
          replyingComment.replies = [action.payload];
        }
      } else
        state.eventComments[action.payload.eventID].comments = [
          action.payload,
          ...prevComments,
        ];
    });
    builder.addCase(newComment.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToComment")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      const prevComments = state.eventComments[action.payload.eventID].comments;
      if (action.payload.replyTo) {
        const replyingComment = state.eventComments[
          action.payload.eventID
        ].comments.find((c) => c._id === action.payload.replyTo);
        replyingComment.replies = replyingComment.replies.filter(
          (c) => c._id !== action.payload._id
        );
      } else
        state.eventComments[action.payload.eventID].comments =
          prevComments.filter((comment) => comment._id !== action.payload._id);
    });
    builder.addCase(deleteComment.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToDeleteComment")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(fetchComments.pending, (state, action) => {
      const { eventID } = action.meta.arg;
      if (!state.eventComments.hasOwnProperty(eventID))
        state.eventComments[eventID] = { status: "loading" };
    });
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      const { comments, eventID } = action.payload;
      state.eventComments[eventID].comments = comments;
      state.eventComments[eventID].status = "success";
    });
    builder.addCase(fetchComments.rejected, (state, action) => {
      const { eventID } = action.meta.arg;
      state.eventComments[eventID].status = "failed";
      state.alertMessage = `${i18n.t("failedToFetchComment")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(addCommentReaction.fulfilled, (state, action) => {
      const { comment, type } = action.payload;
      let com;
      if (comment.replyTo) {
        com = state.eventComments[comment.eventID].comments
          .find((c) => c._id === comment.replyTo)
          .replies.find((c) => c._id === comment._id);
      } else {
        com = state.eventComments[comment.eventID].comments.find(
          (c) => c._id === comment._id
        );
      }
      if (!com.userReaction && type) com.reactionCount += 1;
      else if (com.userReaction && !type) com.reactionCount -= 1;
      com.userReaction = type;
    });
    builder.addCase(addCommentReaction.rejected, (state, action) => {
      state.isAlertMessage = true;
      state.alertMessage = `${i18n.t("failedToReact")} ${action.error.message}`;
      state.alertMessageType = "error";
    });
  },
});

export default commentsSlice.reducer;
export const { setAlertMessage } = commentsSlice.actions;
