import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { Box, Button } from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function FileUploader({
  onFileSelect,
  acceptedFileTypes,
  instructionText = "Drag & drop some files here",
}) {
  const { t } = useTranslation(["common"]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileSelect(acceptedFiles);
    },
    [onFileSelect]
  );

  const onDropRejected = () => {
    toast.error(t("invalidFileUploaded"), {});
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, isFocused } =
    useDropzone({
      onDrop,
      onDropRejected,
      accept: acceptedFileTypes,
    });

  return (
    <Box
      className={`my-2 p-2 border-dashed border-2 border-gray-500/40 transition-colors rounded-sm ${
        isFocused ? "border-gray-500" : ""
      } ${isDragAccept ? "border-green-500" : ""} ${
        isDragReject ? "border-red-500" : ""
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Box className="flex flex-col justify-center items-center cursor-pointer">
        <AddPhotoAlternateOutlinedIcon sx={{ height: "3rem", width: "3rem" }} />
        <Box>{instructionText}</Box>
        {t("or")}
        <Button variant="outlined" sx={{ my: 1 }}>
          {t("selectFiles")}
        </Button>
      </Box>
    </Box>
  );
}
