import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Utils from "../utils/Utils";
const { serverURL } = Utils;

const initialState = {
    status: "",
    data: [],
    user:{},
    conversationsId:""

};

export const messageThunk = createAsyncThunk(
  "chat/message",
  async (data_) => {
    const {conversationId,userId} = data_
    const response = await fetch(`${serverURL}/chat/message/${conversationId}/${userId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  }
);


export const messageSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.data = action.payload;
    },
    setUser:(state, action) => {
      state.user = action.payload.user;
    },
    setConversationsId:(state, action) => {
      state.conversationsId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(messageThunk.fulfilled, (state, action) => {
        const {status} = action.payload
        if (status === 200){
            state.data = action.payload.data;
            state.status = "success";
        } else {
            state.status = "failed";
        }
    });
    builder.addCase(messageThunk.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default messageSlice.reducer;
export const { setMessages,setUser,setConversationsId } = messageSlice.actions;
