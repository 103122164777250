import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import TermsAndPrivacyModal from "../components/TermsAndPrivacyModal";
import { registerUser } from "../redux/authSlice";

export default function Register() {
  const { t } = useTranslation(["auth", "common"]);
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isTermsAndPrivacyModal, setIsTermsAndPrivacyModal] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { status } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      data.email.length > 0 &&
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
        data.email
      )
    )
      setError((prev) => ({ ...prev, email: t("invalidEmail") }));
    else setError((prev) => ({ ...prev, email: "" }));
    if (
      data.password.length > 0 &&
      new RegExp(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/, "g").test(
        data.password
      )
    )
      setError((prev) => ({ ...prev, password: t("invalidPassword") }));
    else setError((prev) => ({ ...prev, password: "" }));
    if (
      data.confirmPassword.length > 0 &&
      data.confirmPassword !== data.password
    )
      setError((prev) => ({
        ...prev,
        confirmPassword: t("passwordNotMatched"),
      }));
    else setError((prev) => ({ ...prev, confirmPassword: "" }));
  }, [data, t]);

  const handleChange = (e) =>
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsTermsAndPrivacyModal(true);
  };
  const handleRegistration = () => {
    setIsTermsAndPrivacyModal(false);
    dispatch(registerUser(data)).then((r) => {
      if (!r.error) navigate("/signup-success");
    });
  };
  return (
    <Box alignSelf="center" className="w-full">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        <Box className="mb-1 flex justify-center">
          <img className="max-w-[4rem]" src="logo.png" alt="Logo" />
        </Box>
        <Typography variant="h4" my={3} textAlign="center">
          {t("register")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            required
          >
            <InputLabel htmlFor="email">{t("email")}</InputLabel>
            <OutlinedInput
              error={Boolean(error.email)}
              id="email"
              onChange={handleChange}
              type="email"
              label={t("email")}
              name="email"
            />
            <FormHelperText error>{error.email}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            required
          >
            <InputLabel htmlFor="password">{t("password")}</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              error={Boolean(error.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("password")}
              name="password"
            />
            <FormHelperText error>{error.password}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{ width: "100%", my: 1, bgcolor: "transparent" }}
            variant="outlined"
            required
          >
            <InputLabel htmlFor="confirm-password">
              {t("confirmPassword")}
            </InputLabel>
            <OutlinedInput
              id="confirm-password"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              error={Boolean(error.confirmPassword)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("confirmPassword")}
              name="confirmPassword"
            />
            <FormHelperText error>{error.confirmPassword}</FormHelperText>
          </FormControl>
          <Box className="flex flex-col">
            <Button
              disabled={
                data.email.trimStart().length === 0 ||
                data.password.trimStart().length === 0 ||
                data.password !== data.confirmPassword
              }
              sx={{
                margin: "1rem auto",
                padding: "0.75rem 1.75rem",
                borderRadius: "28px",
                textTransform: "none",
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              {status === "loading" ? (
                <CircularProgress size={24} sx={{ color: "#FFF" }} />
              ) : (
                t("signUp")
              )}
            </Button>
            <Divider textAlign="center">{t("common:or")}</Divider>
            <Box className="text-center">
              <Typography variant="p">{t("alreadyHaveAccount")}</Typography>
              <NavLink
                to="/login"
                className="text-blue-600 p-2 hover:underline"
              >
                {t("login")}
              </NavLink>
            </Box>
          </Box>
        </form>
      </Paper>
      <TermsAndPrivacyModal
        isTermsAndPrivacyModal={isTermsAndPrivacyModal}
        setIsTermsAndPrivacyModal={setIsTermsAndPrivacyModal}
        handleRegistration={handleRegistration}
      />
    </Box>
  );
}
