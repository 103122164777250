import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import logo from "../assets/logo.png";
import Countdown from "../components/commons/Countdown";
import { verifyEmail } from "../redux/authSlice";

export default function VerifyEmail() {
  const { t } = useTranslation(["auth", "common"]);
  const { verificationStatus } = useSelector((state) => state.auth);
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let ignore = false;
    if (!token || !verificationStatus === null) return;
    const controller = new AbortController();
    (async () => {
      if (!ignore) dispatch(verifyEmail({ token, signal: controller.signal }));
    })();
    return () => {
      controller.abort();
      ignore = true;
    };
  }, []);

  const redirect = () => {
    navigate("/login");
  };
  return (
    <Box className="w-full" alignSelf="center">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        <Box className="flex justify-center" marginBottom="1rem">
          <img src={logo} alt="Logo" />
        </Box>
        {verificationStatus === "verified" && (
          <>
            <Typography
              className="text-green-600"
              variant="h4"
              my={3}
              color="success"
              textAlign="center"
            >
              {t("verificationSuccessful")}
            </Typography>
            <Box className="text-center">
              <Countdown targetTime={10} onRedirect={redirect} />
              <Typography className="py-1">{t("redirectingTo")}</Typography>
              <NavLink to="/login">
                <Button variant="contained">{t("login")}</Button>
              </NavLink>
            </Box>
          </>
        )}
        {verificationStatus === "loading" && (
          <Box className="text-center">
            <CircularProgress />
          </Box>
        )}
        {verificationStatus === "failed" && (
          <Box className="text-center">
            <Typography>{t("verificationFailed")}</Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
