import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";
import Utils from "../utils/Utils";

const { getObjectByPath, pathSeparator, serverURL } = Utils;

const initialState = {
  nodes: {},
  status: "idle",
  error: null,
  searchResult: [],
  searchStatus: "idle",
  suggestions: [],
  suggestionStatus: "idle",
  isAlertMessage: false,
  alertMessage: "",
  alertMessageType: "",
};

export const fetchNodes = createAsyncThunk(
  "nodes/fetchNodes",
  async ({ path, isFull }) => {
    const response = await fetch(`${serverURL}/nodes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ path, isFull }),
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  }
);

export const fetchDeepParent = createAsyncThunk(
  "nodes/fetchDeepParent",
  async ({ path }, thunkAPI) => {
    const properties = path.split(pathSeparator).filter(Boolean).slice(1);
    const state = thunkAPI.getState();
    let parent = state.nodes.nodes;
    let failedLevel;
    for (const property of properties) {
      const match = parent.children.find((child) => child.name === property);
      if (!match) {
        failedLevel = property;
        break;
      }
      parent = match;
    }
    const response = await fetch(`${serverURL}/nodes`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        path: path.split(`${pathSeparator}${failedLevel}`)[0],
        isFull: true,
        endingPath: path,
      }),
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  }
);

export const search = createAsyncThunk("nodes/search", async (key) => {
  const response = await fetch(`${serverURL}/search`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ key }),
  });
  if (!response.ok) throw new Error(await response.text());
  const data = await response.json();
  return data;
});

export const newSuggestion = createAsyncThunk(
  "nodes/newSuggestion",
  async (suggestion) => {
    const response = await fetch(`${serverURL}/suggestions/new`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(suggestion),
    });
    if (!response.ok) throw new Error(await response.text());
    return response.text();
  }
);

export const getSuggestions = createAsyncThunk(
  "nodes/getSuggestions",
  async () => {
    const response = await fetch(`${serverURL}/suggestions`, {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  }
);

export const updateSuggestion = createAsyncThunk(
  "nodes/updateSuggestion",
  async ({ id, action, searchable }) => {
    const response = await fetch(`${serverURL}/suggestions/${action}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ id, action, searchable }),
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  }
);

const nodesSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    clearAlertMessage: (state) => {
      state.alertMessage = "";
      state.alertMessageType = "";
      state.isAlertMessage = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNodes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNodes.fulfilled, (state, action) => {
        const data = action.payload;
        if (data.name) {
          if (data.parent === null) {
            state.nodes = { ...data };
          } else {
            const parent = getObjectByPath(state.nodes, data.path);
            if (parent) parent.children = data.children;
            else {
              state.alertMessage = `${i18n.t("errorOccurred")} ${
                action.error.message
              }`;
              state.alertMessageType = "error";
              state.isAlertMessage = true;
              state.status = "failed";
            }
          }
        }
        state.status = "success";
      })
      .addCase(fetchNodes.rejected, (state, action) => {
        state.error = action.error.message;
        state.alertMessage = `${i18n.t("failedFetchingNodes")} ${
          action.error.message
        }`;
        state.alertMessageType = "error";
        state.isAlertMessage = true;
        state.status = "failed";
      });
    builder
      .addCase(fetchDeepParent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeepParent.fulfilled, (state, action) => {
        const data = action.payload;
        if (data.name) {
          if (data.parent === null) {
            state.nodes = { ...data };
          } else {
            const newParent = getObjectByPath(state.nodes, data.path);
            if (newParent) newParent.children = data.children;
            else {
              state.alertMessage = `${i18n.t("errorOccurred")} ${
                action.error.message
              }`;
              state.alertMessageType = "error";
              state.isAlertMessage = true;
              state.status = "failed";
            }
          }
        }
        state.status = "success";
      })
      .addCase(fetchDeepParent.rejected, (state, action) => {
        state.error = action.error.message;
        state.alertMessage = `${i18n.t("failedFetchingDeepNode")} ${
          action.error.message
        }`;
        state.alertMessageType = "error";
        state.isAlertMessage = true;
        state.status = "failed";
      });
    builder
      .addCase(search.pending, (state) => {
        state.searchStatus = "loading";
      })
      .addCase(search.fulfilled, (state, action) => {
        state.searchResult = action.payload;
        state.searchStatus = "success";
      })
      .addCase(search.rejected, (state, action) => {
        state.error = action.error.message;
        state.alertMessage = `${i18n.t("searchFailed")} ${
          action.error.message
        }`;
        state.alertMessageType = "error";
        state.isAlertMessage = true;
        state.searchStatus = "failed";
      });
    builder
      .addCase(newSuggestion.fulfilled, (state) => {
        state.alertMessage = i18n.t("suggestionSubmitted");
        state.alertMessageType = "success";
        state.isAlertMessage = true;
      })
      .addCase(newSuggestion.rejected, (state, action) => {
        state.alertMessageType = "error";
        state.alertMessage = `${i18n.t("suggestionFailed")} ${
          action.error.message
        }`;
        state.isAlertMessage = true;
      });
    builder
      .addCase(getSuggestions.pending, (state) => {
        state.suggestionStatus = "loading";
      })
      .addCase(getSuggestions.fulfilled, (state, action) => {
        state.suggestions = action.payload;
        state.suggestionStatus = "success";
      })
      .addCase(getSuggestions.rejected, (state, action) => {
        state.error = action.error.message;
        state.alertMessage = `${i18n.t("suggestionLoadingFailed")} ${
          action.error.message
        }`;
        state.alertMessageType = "error";
        state.suggestionStatus = "failed";
        state.isAlertMessage = true;
      });
    builder
      .addCase(updateSuggestion.fulfilled, (state, action) => {
        state.suggestions = action.payload;
        state.suggestionStatus = "success";
      })
      .addCase(updateSuggestion.rejected, (state, action) => {
        state.error = action.error.message;
        state.alertMessage = `${i18n.t("failedToUpdate")} ${
          action.error.message
        }`;
        state.alertMessageType = "error";
        state.suggestionStatus = "failed";
        state.isAlertMessage = true;
      });
  },
});

export default nodesSlice.reducer;
export const { clearAlertMessage } = nodesSlice.actions;
