import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Box, Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export default function Footer({ mode, setMode }) {
  const { t } = useTranslation(["navbar"]);
  const theme = useTheme();
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        px: 2,
        py: 1,
        gap: 1,
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      <NavLink className="text-blue-600 p-2 hover:underline" to="/terms">
        {t("terms")}
      </NavLink>
      <NavLink className="text-blue-600 p-2 hover:underline" to="/privacy">
        {t("privacy")}
      </NavLink>
      <div className=" border-r-2 border-gray-500/50 self-stretch my-1.5"></div>
      <Button
        onClick={() => {
          setMode((prev) => (prev === "light" ? "dark" : "light"));
        }}
        color="inherit"
        sx={{ px: 1.5 }}
        startIcon={
          mode === "dark" ? (
            <Brightness7Icon fontSize="small" />
          ) : (
            <Brightness4Icon fontSize="small" />
          )
        }
      >
        {t("toggleTheme")}
      </Button>
    </Box>
  );
}
