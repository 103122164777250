import { useEffect, useState } from "react";

export default function usePreferredTheme() {
  const [preferredColorScheme, setPreferredColorScheme] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"
  );

  useEffect(() => {
    const query =
      window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");

    if (query) {
      const handleChange = (event) => {
        setPreferredColorScheme(event.matches ? "dark" : "light");
      };

      query.addListener(handleChange);

      return () => {
        query.removeListener(handleChange);
      };
    }
  }, []);

  return preferredColorScheme;
}
