import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
    counter: 0,
    status:""
};

export const getNotificationCounter = createAsyncThunk(
  "chat/notification",
  async (userId) => {
    const response = await fetch(`${serverURL}/chat/notification/${userId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const data = await response.json();
    return data;
  }
);


export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotificationCounter.fulfilled, (state, action) => {
      state.counter = action.payload.counter;
      state.status = "success";
    });
    builder.addCase(getNotificationCounter.rejected, (state, action) => {
      state.counter = 0
      state.status = "failed";
    });
  },
});

export default notificationSlice.reducer;
