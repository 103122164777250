import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import LockIcon from "@mui/icons-material/Lock";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PublicIcon from "@mui/icons-material/Public";
import { ImageList, ImageListItem, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createNew, editPost, setAlertMessage } from "../../redux/postsSlice";
import Utils from "../../utils/Utils";
import Location from "../Location";
import FileUploader from "../commons/FileUploader";

const { postBorderColors, pathToFormattedDirectory } = Utils;

export default function PostCreationModal({
  profile,
  newPostModalOpened,
  setNewPostModalOpened,
  oldPost,
  setEditingPost,
  editPostFunction,
}) {
  const { t } = useTranslation(["posts-events", "common"]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isPrivate, setIsPrivate] = useState(false);
  const [text, setText] = useState("");
  const [path, setPath] = useState("");
  const [type, setType] = useState("select");
  const [isCommercial, setIsCommercial] = useState(false);
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [mapOpen, setMapOpen] = useState(false);
  const [imageUploaderOpen, setImageUploaderOpen] = useState(false);
  const [location, setLocation] = useState(profile?.defaultLocation);

  const handleClose = () => {
    setNewPostModalOpened(false);
  };

  useEffect(() => {
    if (!oldPost?.path) return;
    setIsPrivate(oldPost?.isPrivate || false);
    setText(oldPost?.description || "");
    setPath(
      profile.subscribedInterests
        .map((item) => item.path)
        .includes(oldPost?.path)
        ? oldPost.path
        : ""
    );
    setImages(oldPost?.images);
    setType(oldPost?.type || "select");
    setIsCommercial(oldPost?.isCommercial || false);
    setLocation(oldPost?.location || profile?.defaultLocation);
  }, [oldPost, profile.subscribedInterests, profile.defaultLocation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const post = {
      ...oldPost,
      authorID: profile._id,
      path,
      location,
      description: text,
      isPrivate,
      images,
      type,
      isCommercial,
    };
    // create the formData to send to the server
    const formData = new FormData();
    formData.append("postBody", JSON.stringify(post));
    imageFiles.forEach((image) =>
      formData.append("imageFiles", image, image.name)
    );

    if (
      !post.path ||
      !profile.subscribedInterests.map((item) => item.path).includes(post.path)
    )
      return dispatch(
        setAlertMessage({
          message: t("noDirectory"),
          type: "error",
          show: true,
        })
      );
    if (!post.location || isNaN(post.location.lat))
      return dispatch(
        setAlertMessage({ message: t("noLocation"), type: "error", show: true })
      );
    if (!post.description && post.images?.length === 0)
      return dispatch(
        setAlertMessage({
          message: t("noDescriptionOrImage"),
          type: "error",
          show: true,
        })
      );
    if (!post.authorID || post.isPrivate == null || !post.type)
      return dispatch(
        setAlertMessage({
          message: t("invalidPost"),
          type: "error",
          show: true,
        })
      );
    if (post.type === "select")
      return dispatch(
        setAlertMessage({ message: t("noType"), type: "error", show: true })
      );
    if (oldPost?._id) {
      if (!editPostFunction) return dispatch(editPost(formData));
      dispatch(editPostFunction(formData));
    } else dispatch(createNew(formData));
    setIsPrivate(false);
    setText("");
    setPath("");
    setType("select");
    setIsCommercial(false);
    setImages([]);
    setMapOpen(false);
    setEditingPost({});
    setImageUploaderOpen(false);
    setImageUploaderOpen(false);
    setImageFiles([]);
    return setNewPostModalOpened(false);
  };

  const handleUpload = (files) => {
    setImageFiles((prev) => prev.concat(files));
  };

  // build an array to display preview of both image urls or newly uploaded files
  const imageUrlOrFiles = images.concat(imageFiles);
  // remove either from the images links or image files
  const removeImage = (img) => {
    if (img.url) setImages(images.filter((item) => item.url !== img.url));
    else setImageFiles(imageFiles.filter((item) => item.path !== img.path));
  };

  return (
    <Modal
      aria-labelledby="Create new post"
      aria-describedby="Create a new post"
      open={newPostModalOpened}
      onClose={handleClose}
      closeAfterTransition
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 0",
      }}
    >
      <Zoom in={newPostModalOpened}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            zIndex: 1,
            top: 0,
            maxWidth: "600px",
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            margin: "auto",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <IconButton onClick={handleClose} sx={{ ml: "auto" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                my: 1.5,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                src={profile.profilePic?.url || ""}
                sx={{
                  width: 32,
                  height: 32,
                }}
              />
              <Typography variant="h6" component="p" sx={{ m: 1, mr: "auto" }}>
                {profile.username}
              </Typography>
              <FormControl sx={{ m: 0.5 }} size="small">
                <Select
                  labelId="visibility"
                  id="visibility"
                  value={isPrivate ? "private" : "public"}
                  onChange={(e) => setIsPrivate(e.target.value === "private")}
                  sx={{
                    "& > div": {
                      display: "flex",
                      alignItems: "center",
                      p: 0.25,
                      "& > div": {
                        minWidth: "unset",
                        ml: 1,
                      },
                    },
                  }}
                >
                  <MenuItem value="public">
                    <ListItemIcon>
                      <PublicIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:public")}</ListItemText>
                  </MenuItem>
                  <MenuItem value="private">
                    <ListItemIcon>
                      <LockIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:private")}</ListItemText>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                my: 0.5,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{ borderRadius: 10, m: 0.5 }}
                startIcon={<EditLocationOutlinedIcon />}
                onClick={() => setMapOpen((prev) => !prev)}
              >
                {t("editLocation")}
              </Button>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                }}
                size="small"
              >
                <InputLabel id="path-label">{t("selectDirectory")}</InputLabel>
                <Select
                  labelId="path-label"
                  id="path"
                  label={t("selectDirectory")}
                  value={path}
                  onChange={(e) => setPath(e.target.value)}
                >
                  {profile.subscribedInterests.map((item) => (
                    <MenuItem key={item.path} value={item.path}>
                      {pathToFormattedDirectory(item.path)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                color="inherit"
                sx={{ borderRadius: 10, m: 0.5 }}
                startIcon={<AddPhotoAlternateOutlinedIcon />}
                onClick={() => setImageUploaderOpen((prev) => !prev)}
              >
                {t("addImage")}
              </Button>
            </Box>
            <Box sx={{ display: "flex", my: 0.5, flexWrap: "wrap" }}>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                }}
                size="small"
              >
                <Select
                  sx={{
                    bgcolor: postBorderColors[type],
                    color:
                      postBorderColors[type] &&
                      theme.palette.getContrastText(postBorderColors[type]),
                  }}
                  labelId="type"
                  id="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem
                    value="select"
                    sx={{
                      bgcolor: "inherit",
                      color: "inherit",
                    }}
                  >
                    {t("postType")}
                  </MenuItem>
                  <MenuItem
                    value="ask"
                    sx={{
                      bgcolor: postBorderColors.ask,
                      color: theme.palette.getContrastText(
                        postBorderColors.ask
                      ),
                    }}
                  >
                    {t("ask")}
                  </MenuItem>
                  <MenuItem
                    value="tell"
                    sx={{
                      bgcolor: postBorderColors.tell,
                      color: theme.palette.getContrastText(
                        postBorderColors.tell
                      ),
                    }}
                  >
                    {t("tell")}
                  </MenuItem>
                  <MenuItem
                    value="event-feeler"
                    sx={{
                      bgcolor: postBorderColors["event-feeler"],
                      color: theme.palette.getContrastText(
                        postBorderColors["event-feeler"]
                      ),
                    }}
                  >
                    {t("eventFeeler")}
                  </MenuItem>
                </Select>
              </FormControl>
              <Tooltip title={t("commercialDisclaimer")} placement="top">
                <FormControlLabel
                  sx={{
                    m: 1,
                    pr: 1,
                    color: theme.palette.getContrastText(
                      postBorderColors.commercial
                    ),
                    bgcolor: postBorderColors.commercial,
                    borderRadius: 1,
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: theme.palette.getContrastText(
                          postBorderColors.commercial
                        ),
                      }}
                      checked={isCommercial}
                      onChange={(e) => setIsCommercial(e.target.checked)}
                    />
                  }
                  label={t("commercial")}
                />
              </Tooltip>
            </Box>
            {imageUploaderOpen && (
              <FileUploader
                acceptedFileTypes={{ "image/*": [] }}
                onFileSelect={handleUpload}
                instructionText={t("dragAndDropImage")}
              />
            )}
            {mapOpen && (
              <Location location={location} setLocation={setLocation} />
            )}
            <TextField
              id="standard-textarea"
              placeholder={`${t("whatsOnMind")}, ${profile.username}?`}
              multiline
              variant="outlined"
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <Box className="my-2">
              <ImageList variant="masonry" cols={2} gap={8}>
                {imageUrlOrFiles.map((img) => (
                  <ImageListItem key={img.path || img.url}>
                    <Box
                      key={img.path || img.url}
                      className="group"
                      sx={{
                        "& img": {
                          borderRadius: 1,
                          objectFit: "cover",
                          width: "100%",
                        },
                        position: "relative",
                      }}
                    >
                      <img
                        srcSet={img.url ? img.url : URL.createObjectURL(img)}
                        src={img.url ? img.url : URL.createObjectURL(img)}
                        alt={t("post")}
                        loading="lazy"
                      />
                      <div className="bg-white/25 transition-colors group-hover:bg-white/100 dark:bg-slate-900/25 dark:group-hover:bg-slate-900 absolute top-0 right-0 rounded-full">
                        <IconButton onClick={() => removeImage(img)}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Box>
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Button
                variant="contained"
                color="success"
                type="submit"
                sx={{ borderRadius: 10, m: 0.5 }}
                startIcon={<PostAddIcon />}
              >
                {t("post")}
              </Button>
            </Box>
          </form>
        </Paper>
      </Zoom>
    </Modal>
  );
}
