import { SvgIcon } from '@mui/material';

export default function ActiveDirectoryIcon({ sx, theme }) {
    return (
        <SvgIcon sx={sx}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 450"
                style={{ height: '512px', width: '450px' }}
            >
                <g className="" transform="translate(0,0)">
                    <path
                        d="M25.01 49v46H103V49zM153 49v46h78V49zm128 0v46h78V49zm128 0v46h78V49zM55.01 113v64H119v46h18v-46h64v-64h-18v46H73.01v-46zM311 113v64h64v46h18v-46h64v-64h-18v46H329v-46zM89.01 241v46H167v-46zM345 241v46h78v-46zm-226 64v48h128v46h18v-46h128v-48h-18v30H137v-30zm98 112v46h78v-46z"
                        fill={theme.palette.primary.main}
                        fillOpacity="1"
                    />
                </g>
            </svg>
        </SvgIcon>
    );
}
