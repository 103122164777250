const pathSeparator = "//"; // Can be any characters of any length, remember to change in backend too
const serverURL =  process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";
const socketURL =  process.env.NODE_ENV === "production" ? "" : "http://localhost:4000";

const postBorderColors = {
  ask: "#ffc7c7",
  tell: "#e0d2f7",
  "event-feeler": "#f9d7b8",
  commercial: "#b8f0f9",
  event: "#f9f4b8",
};

const currencies = [
  {
    name: "EUR",
    sign: "€",
  },
  {
    name: "USD",
    sign: "$",
  },
];

const urlRegex = /((https?:\/\/)?[^\s]+(\.[^\s]+)+)/g;

function splitText(text) {
  const segments = [];
  let match;

  let lastIndex = 0;
  while ((match = urlRegex.exec(text)) !== null) {
    const linkText = match[0];
    const linkIndex = match.index;

    if (linkIndex > lastIndex) {
      const nonLinkText = text.slice(lastIndex, linkIndex);
      segments.push({ text: nonLinkText, isLink: false });
    }

    segments.push({ text: linkText, isLink: true });
    lastIndex = linkIndex + linkText.length;
  }

  if (lastIndex < text.length) {
    const nonLinkText = text.slice(lastIndex);
    segments.push({ text: nonLinkText, isLink: false });
  }

  return segments.map((segment) => {
    if (segment.isLink) {
      segment.url = segment.text;
      if (!segment.url.startsWith("http")) {
        segment.url = `https://${segment.url}`;
      }
    }
    return segment;
  });
}

function getObjectByPath(obj, path) {
  let parent = obj;
  const properties = path.split(pathSeparator).filter(Boolean).slice(1);
  for (const property of properties) {
    const match = parent.children.find((child) => child.name === property);
    if (!match) {
      return null;
    }
    parent = match;
  }
  return parent;
}

function stringIncludesCaseInsensitive(string, searchSubstring) {
  return string.toLowerCase().includes(searchSubstring.toLowerCase());
}

function toFixedWithoutLeadingZeros(number) {
  const fixed = number.toFixed(1);
  return fixed.replace(/\.0$/, "");
}

function FormatNumber(number) {
  if (isNaN(number)) return Number(0);
  if (number < 1000) {
    return number;
  }
  if (number < 1000000) {
    return `${toFixedWithoutLeadingZeros(number / 1000)}k`;
  }
  if (number < 1000000000) {
    return `${toFixedWithoutLeadingZeros(number / 1000000)}M`;
  }
  return `${toFixedWithoutLeadingZeros(number / 1000000000)}B`;
}
function pathToFormattedDirectory(path) {
  return path.split(pathSeparator).filter(Boolean).slice(-3).join(" / ");
}
function getUserFeedLink(path, id) {
  const paths = path
    .split(pathSeparator)
    .filter(Boolean)
    .slice(-2)
    .map((item) => encodeURIComponent(item))
    .join("/");
  return `/${paths}?user=${id}`;
}

export default {
  getObjectByPath,
  stringIncludesCaseInsensitive,
  formatNumber: FormatNumber,
  splitText,
  pathToFormattedDirectory,
  getUserFeedLink,
  urlRegex,
  pathSeparator,
  serverURL,
  socketURL,
  currencies,
  postBorderColors,
};
