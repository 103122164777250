import { Typography } from '@mui/material';
import Utils from '../utils/Utils';

const { pathSeparator } = Utils;

function PostDirectory({ post, sx }) {
    const paths = post.path.split(pathSeparator).filter(Boolean).slice(-3).join(' / ');
    return (
        <Typography sx={sx} my={0.5}>
            {paths}
        </Typography>
    );
}

export default PostDirectory;
