import { Box, Button, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import requestAcceptSVG from "../assets/undraw_accept_request_re_d81h.svg";
import Alert from "../components/Alert";
import Loader from "../components/Loader";
import NodeTree from "../components/NodeTree";
import {
  clearAlertMessage,
  fetchNodes,
  getSuggestions,
  updateSuggestion,
} from "../redux/nodesSlice";
import Utils from "../utils/Utils";

const { pathSeparator } = Utils;
const escapedSeparator = pathSeparator.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");

function Admin() {
  const {
    status,
    suggestions,
    suggestionStatus,
    alertMessage,
    isAlertMessage,
    alertMessageType,
    nodes,
  } = useSelector((state) => state.nodes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSuggestions());
    dispatch(fetchNodes({ path: pathSeparator, isFull: true }));
  }, [dispatch]);
  const handleClick = (id, action, searchable) => {
    dispatch(updateSuggestion({ id, action, searchable }));
  };
  return (
    <Box mx="auto">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
          overflow: "auto",
        }}
      >
        <Typography variant="h4" component="h1" textAlign="center" my={2}>
          Review node suggestions
        </Typography>
        <Box sx={{ textAlign: "center", my: 2 }}>
          <img
            src={requestAcceptSVG}
            alt="Node"
            style={{ maxWidth: "200px" }}
          />
        </Box>
        {suggestionStatus === "idle" || suggestionStatus === "loading" ? (
          <Loader />
        ) : (
          <Box my={3}>
            {suggestions.length > 0 ? (
              suggestions.map((node) => (
                <Paper
                  elevation={2}
                  sx={{
                    borderRadius: 2,
                    padding: 2,
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "space-between",
                    my: 1,
                  }}
                  key={node.path}
                >
                  <Box>
                    <Typography fontWeight="bold" variant="h6">
                      {" "}
                      {node.name}
                    </Typography>
                    <Typography>
                      <Typography fontWeight="bold" variant="span">
                        Path:{" "}
                      </Typography>
                      {node.path
                        .slice(pathSeparator.length)
                        .replace(new RegExp(escapedSeparator, "g"), " / ")}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Button
                      color="success"
                      sx={{ my: 0.5, borderRadius: 5 }}
                      variant="outlined"
                      onClick={() => handleClick(node._id, "approve", true)}
                    >
                      Approve as searchable
                    </Button>
                    <Button
                      color="warning"
                      sx={{ my: 0.5, borderRadius: 5 }}
                      variant="outlined"
                      onClick={() => handleClick(node._id, "approve", false)}
                    >
                      Approve as not searchable
                    </Button>
                    <Button
                      color="error"
                      sx={{ my: 0.5, borderRadius: 5 }}
                      variant="outlined"
                      onClick={() => handleClick(node._id, "delete")}
                    >
                      Delete
                    </Button>
                  </Box>
                </Paper>
              ))
            ) : (
              <Box sx={{ textAlign: "center", my: 3 }}>
                <Typography
                  variant="span"
                  sx={{ border: "1px dashed gray", p: 1 }}
                >
                  Nothing here
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <Typography variant="h5" component="h2" textAlign="center">
          Full Node Tree:
        </Typography>
        {status === "success" ? (
          <Box className="tree" sx={{ mt: 2 }}>
            {nodes && nodes.name && <NodeTree nodes={[nodes]} />}
          </Box>
        ) : (
          <Loader />
        )}
        <Alert
          isAlertMessage={isAlertMessage}
          alertMessage={alertMessage}
          alertMessageType={alertMessageType}
          onClose={() => dispatch(clearAlertMessage())}
        />
      </Paper>
    </Box>
  );
}

export default Admin;
