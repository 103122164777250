import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
  userId: null,
  isModalShown: false,
  userImages: null,
  status: "idle",
};

export const fetchUserImages = createAsyncThunk(
  "userImages/fetchUserImages",
  async (userId) => {
    const response = await fetch(`${serverURL}/find-user/images/${userId}`, {
      method: "POST",
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const result = await response.json();
    return result;
  }
);

export const userImagesSlice = createSlice({
  name: "userImages",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.isModalShown = !state.isModalShown;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserImages.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchUserImages.fulfilled, (state, action) => {
      state.userImages = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchUserImages.rejected, (state) => {
      state.status = "failed";
    });
  },
});

export default userImagesSlice.reducer;
export const { toggleModal, setUserId } = userImagesSlice.actions;
