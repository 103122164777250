import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import LockIcon from "@mui/icons-material/Lock";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PublicIcon from "@mui/icons-material/Public";
import { ImageList, ImageListItem, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createNew, editEvent, setAlertMessage } from "../../redux/eventsSlice";
import Utils from "../../utils/Utils";
import Location from "../Location";
import FileUploader from "../commons/FileUploader";

const { postBorderColors, currencies, pathToFormattedDirectory } = Utils;

export default function EventCreationModal({
  profile,
  newEventModalOpened,
  setNewEventModalOpened,
  oldEvent,
  editEventFunction,
  setEditingEvent,
}) {
  const { t, i18n } = useTranslation(["posts-events", "common"]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleClose = () => setNewEventModalOpened(false);
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [path, setPath] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [isCommercial, setIsCommercial] = useState(false);
  const [costs, setCosts] = useState({ amount: 0, currency: "EUR" });
  const [dateTime, setDateTime] = useState(dayjs(new Date()));
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [mapOpen, setMapOpen] = useState(false);
  const [imageUploaderOpen, setImageUploaderOpen] = useState(false);
  const [location, setLocation] = useState(profile?.defaultLocation);

  useEffect(() => {
    if (!oldEvent?.name) return;
    setName(oldEvent?.name || "");
    setText(oldEvent?.description || "");
    setPath(
      profile.subscribedInterests
        .map((item) => item.path)
        .includes(oldEvent?.path)
        ? oldEvent?.path
        : ""
    );
    setImages(oldEvent?.images);
    setDateTime(dayjs(oldEvent?.dateTime || new Date()));
    setIsPrivate(oldEvent?.isPrivate || false);
    setIsCommercial(oldEvent?.isCommercial || false);
    setCosts(oldEvent?.costs || { amount: 0, currency: "EUR" });
    setLocation(oldEvent?.location);
  }, [oldEvent, profile.subscribedInterests]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const event = {
      ...oldEvent,
      authorID: profile._id,
      path,
      costs: { amount: costs.amount || 0, currency: costs.currency },
      name,
      dateTime: dayjs(dateTime).toISOString(),
      location,
      description: text,
      images,
      isPrivate,
      isCommercial,
    };
    // create the formData to send to the server
    const formData = new FormData();
    formData.append("eventBody", JSON.stringify(event));
    imageFiles.forEach((image) =>
      formData.append("imageFiles", image, image.name)
    );

    if (
      !event.path ||
      !profile.subscribedInterests.map((item) => item.path).includes(event.path)
    )
      return dispatch(
        setAlertMessage({
          message: t("noDirectory"),
          type: "error",
          show: true,
        })
      );
    if (!event.location || isNaN(event.location.lat))
      return dispatch(
        setAlertMessage({ message: t("noLocation"), type: "error", show: true })
      );
    if (!event.name)
      return dispatch(
        setAlertMessage({
          message: t("noEventName"),
          type: "error",
          show: true,
        })
      );

    if (!event.dateTime) {
      return dispatch(
        setAlertMessage({
          message: t("noEventDateOrTime"),
          type: "error",
          show: true,
        })
      );
    }
    if (new Date(event.dateTime) - new Date() < 0)
      return dispatch(
        setAlertMessage({
          message: t("pastDate"),
          type: "error",
          show: true,
        })
      );
    if (
      !event.costs ||
      isNaN(event.costs.amount) ||
      event.costs.amount < 0 ||
      !event.costs.currency
    )
      return dispatch(
        setAlertMessage({
          message: t("invalidCost"),
          type: "error",
          show: true,
        })
      );
    if (!event.description && event.images.length === 0)
      return dispatch(
        setAlertMessage({
          message: t("noDescriptionOrImage"),
          type: "error",
          show: true,
        })
      );
    if (!event.authorID)
      return dispatch(
        setAlertMessage({
          message: t("invalidPost"),
          type: "error",
          show: true,
        })
      );
    if (oldEvent?._id) {
      if (!editEventFunction) return dispatch(editEvent(formData));
      dispatch(editEventFunction(formData));
    } else dispatch(createNew(formData));
    setName("");
    setText("");
    setPath("");
    setCosts({ amount: 0, currency: "EUR" });
    setIsPrivate(false);
    setIsCommercial(false);
    setImages([]);
    setMapOpen(false);
    setEditingEvent({});
    setImageUploaderOpen(false);
    setImageFiles([]);
    return setNewEventModalOpened(false);
  };

  const handleUpload = (files) => {
    setImageFiles((prev) => prev.concat(files));
  };

  // build an array to display preview of both image urls or newly uploaded files
  const imageUrlOrFiles = images.concat(imageFiles);
  // remove either from the images links or image files
  const removeImage = (img) => {
    if (img.url) setImages(images.filter((item) => item.url !== img.url));
    else setImageFiles(imageFiles.filter((item) => item.path !== img.path));
  };

  return (
    <Modal
      aria-labelledby="create-new-event"
      aria-describedby="Create a new event"
      open={newEventModalOpened}
      onClose={handleClose}
      closeAfterTransition
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 0",
      }}
    >
      <Zoom in={newEventModalOpened}>
        <Paper
          elevation={2}
          sx={{
            zIndex: 1,
            display: "block",
            top: 0,
            maxWidth: "600px",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            margin: "auto",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <IconButton onClick={handleClose} sx={{ ml: "auto" }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ my: 1.5, display: "flex", alignItems: "center" }}>
              <Avatar
                src={profile.profilePic?.url || ""}
                sx={{
                  width: 32,
                  height: 32,
                }}
              />
              <Typography variant="h6" component="p" sx={{ m: 1, mr: "auto" }}>
                {profile.username}
              </Typography>
              <FormControl sx={{ m: 0.5 }} size="small">
                <Select
                  labelId="visibility"
                  id="visibility"
                  value={isPrivate ? "private" : "public"}
                  onChange={(e) => setIsPrivate(e.target.value === "private")}
                  sx={{
                    "& > div": {
                      display: "flex",
                      alignItems: "center",
                      p: 0.25,
                      "& > div": {
                        minWidth: "unset",
                        ml: 1,
                      },
                    },
                  }}
                >
                  <MenuItem value="public">
                    <ListItemIcon>
                      <PublicIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:public")}</ListItemText>
                  </MenuItem>
                  <MenuItem value="private">
                    <ListItemIcon>
                      <LockIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("common:private")}</ListItemText>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                my: 0.5,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{ borderRadius: 10, m: 0.5 }}
                startIcon={<EditLocationOutlinedIcon />}
                onClick={() => setMapOpen((prev) => !prev)}
              >
                {t("editLocation")}
              </Button>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: 120,
                }}
                size="small"
              >
                <InputLabel id="path-label">{t("selectDirectory")}</InputLabel>
                <Select
                  labelId="path-label"
                  id="path"
                  label={t("selectDirectory")}
                  value={path}
                  onChange={(e) => setPath(e.target.value)}
                >
                  {profile.subscribedInterests.map((item) => (
                    <MenuItem key={item._id} value={item.path}>
                      {pathToFormattedDirectory(item.path)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                color="inherit"
                sx={{ borderRadius: 10, m: 0.5 }}
                startIcon={<AddPhotoAlternateOutlinedIcon />}
                onClick={() => setImageUploaderOpen((prev) => !prev)}
              >
                {t("addImage")}
              </Button>
              <Tooltip title={t("commercialDisclaimer")} placement="top">
                <FormControlLabel
                  sx={{
                    m: 1,
                    pr: 1,
                    color: theme.palette.getContrastText(
                      postBorderColors.commercial
                    ),
                    bgcolor: postBorderColors.commercial,
                    borderRadius: 1,
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: theme.palette.getContrastText(
                          postBorderColors.commercial
                        ),
                      }}
                      checked={isCommercial}
                      onChange={(e) => setIsCommercial(e.target.checked)}
                    />
                  }
                  label={t("commercial")}
                />
              </Tooltip>
            </Box>
            {imageUploaderOpen && (
              <FileUploader
                acceptedFileTypes={{ "image/*": [] }}
                onFileSelect={handleUpload}
                instructionText={t("dragAndDropImage")}
              />
            )}
            {mapOpen && (
              <Location location={location} setLocation={setLocation} />
            )}
            <Box sx={{ display: "flex", my: 0.5, mt: 1, flexWrap: "nowrap" }}>
              <FormControl fullWidth sx={{ mr: 0.5 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  {t("amount")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  startAdornment={
                    <InputAdornment position="start">
                      {currencies.find((c) => c.name === costs?.currency)?.sign}
                    </InputAdornment>
                  }
                  label={t("amount")}
                  type="number"
                  value={costs?.amount}
                  onChange={(e) =>
                    setCosts({ ...costs, amount: e.target.value })
                  }
                />
              </FormControl>
              <TextField
                id="outlined-select-currency"
                select
                label={t("currency")}
                value={costs.currency}
                onChange={(event) =>
                  setCosts({ ...costs, currency: event.target.value })
                }
                sx={{ minWidth: 120, ml: 0.5 }}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.sign}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ display: "flex", my: 0.5, mt: 1, flexWrap: "nowrap" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language}
              >
                <DateTimePicker
                  label={t("dateAndTime")}
                  sx={{ width: "100%" }}
                  value={dateTime}
                  onChange={(value) => setDateTime(value)}
                  format="LLL"
                />
              </LocalizationProvider>
            </Box>
            <TextField
              id="standard-textarea-name"
              placeholder={t("eventName")}
              multiline
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ my: 0.5 }}
            />
            <TextField
              id="standard-textarea-desc"
              placeholder={t("eventDescription")}
              multiline
              variant="outlined"
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
              sx={{ my: 0.5 }}
            />

            <Box className="my-2">
              <ImageList variant="masonry" cols={2} gap={8}>
                {imageUrlOrFiles.map((img) => (
                  <ImageListItem key={img.path || img.url}>
                    <Box
                      key={img.path || img.url}
                      className="group"
                      sx={{
                        "& img": {
                          borderRadius: 1,
                          objectFit: "cover",
                          width: "100%",
                        },
                        position: "relative",
                      }}
                    >
                      <img
                        srcSet={img.url ? img.url : URL.createObjectURL(img)}
                        src={img.url ? img.url : URL.createObjectURL(img)}
                        alt={t("post")}
                        loading="lazy"
                      />
                      <div className="bg-white/25 transition-colors group-hover:bg-white/100 dark:bg-slate-900/25 dark:group-hover:bg-slate-900 absolute top-0 right-0 rounded-full">
                        <IconButton onClick={() => removeImage(img)}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Box>
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Button
                variant="contained"
                color="success"
                type="submit"
                sx={{ borderRadius: 10, m: 0.5 }}
                startIcon={<PostAddIcon />}
              >
                {t("post")}
              </Button>
            </Box>
          </form>
        </Paper>
      </Zoom>
    </Modal>
  );
}
