import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const languages = [
    { code: 'en', nativeName: 'English' },
    { code: 'es', nativeName: 'Español' },
    { code: 'fr', nativeName: 'Français' },
    { code: 'de', nativeName: 'Deutsch' },
    { code: 'bn', nativeName: 'বাংলা' },
];

i18n.allLanguages = languages;

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['cookie', 'localStorage', 'navigator'],
            caches: ['cookie'],
        },
        fallbackLng: 'en',
        supportedLngs: languages.map((l) => l.code),
        debug: false,
        useSuspense: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
