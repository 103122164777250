import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Utils from "../../utils/Utils";
import User from "../User";
import UserAvatar from "../UserAvatar";
import CommentBox from "./CommentBox";
import Reaction from "./Reaction";

const { formatNumber } = Utils;

function Comment({
  comment,
  profile,
  deleteComment,
  replyDisabled = false,
  postComment,
  addReactionToComment,
  interactionDisabled,
}) {
  const { t, i18n } = useTranslation(["posts-events", "common"]);
  const { language } = i18n;
  const [isReply, setIsReply] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        ml: comment.replyTo ? 1 : null,
      }}
    >
      <UserAvatar
        userId={comment.authorID}
        user={comment.author}
        username={comment.username}
      />
      <Box
        sx={{
          width: "100%",
          pl: 1,
        }}
      >
        <Paper
          elevation={1}
          sx={{
            borderRadius: 2,
            p: 0,
            py: 0.5,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <User username={comment.author.username} showMessage={true}/>
            <Typography sx={{ ml: 1, mr: 0.5 }} variant="p" fontSize="small">
              {DateTime.fromISO(comment.createdAt)
                .setLocale(language)
                .toRelative()}
            </Typography>
          </Box>
          <Typography sx={{ whiteSpace: "pre-wrap", px: 1.5 }}>
            {comment.comment}
          </Typography>
        </Paper>
        <Box sx={{ display: "flex", alignItems: "center", pt: 0.25, pb: 0.5 }}>
          {!interactionDisabled && (
            <Reaction
              type="comment-reaction"
              reaction={comment.userReaction}
              react={(r) =>
                addReactionToComment({
                  comment,
                  type: r,
                })
              }
            />
          )}
          {!replyDisabled && !interactionDisabled && (
            <Button
              size="small"
              color="inherit"
              m={0.5}
              onClick={() => setIsReply((prev) => !prev)}
              sx={{ minWidth: "unset" }}
            >
              <Typography fontSize="small">{t("common:reply")}</Typography>
            </Button>
          )}
          {!interactionDisabled && profile._id === comment.userID && (
            <Button
              size="small"
              color="inherit"
              m={0.5}
              onClick={() => deleteComment(comment)}
              sx={{ minWidth: "unset" }}
            >
              <Typography fontSize="small">{t("common:delete")}</Typography>
            </Button>
          )}
          {!isNaN(comment.reactionCount) && comment.reactionCount > 0 && (
            <Typography
              color="inherit"
              m={0.5}
              fontSize="small"
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {`${formatNumber(comment.reactionCount)} ${t("reactions")}`}
            </Typography>
          )}
          {!isNaN(comment.reactionCount) && comment.reactionCount > 0 && (
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Box
                sx={{
                  p: 1,
                  "& > div": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& img": {
                      width: "2rem",
                    },
                  },
                }}
              >
                {comment.reactionsByTypes?.check > 0 && (
                  <Box>
                    <img src="check.svg" alt="check" />
                    <Typography ml={2} mr={1}>
                      {formatNumber(comment.reactionsByTypes.check)}
                    </Typography>
                  </Box>
                )}
                {comment.reactionsByTypes?.clap > 0 && (
                  <Box>
                    <img src="clap.svg" alt="clap" />
                    <Typography ml={2} mr={1}>
                      {formatNumber(comment.reactionsByTypes.clap)}
                    </Typography>
                  </Box>
                )}
                {comment.reactionsByTypes?.interesting > 0 && (
                  <Box>
                    <img src="interesting.svg" alt="interesting" />
                    <Typography ml={2} mr={1}>
                      {formatNumber(comment.reactionsByTypes.interesting)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Popover>
          )}
        </Box>
        {!replyDisabled && isReply && !interactionDisabled && (
          <CommentBox postComment={postComment} replyTo={comment._id} />
        )}
        {comment.replies?.map((c) => (
          <Comment
            key={c._id}
            comment={c}
            profile={profile}
            deleteComment={deleteComment}
            addReactionToComment={addReactionToComment}
            replyDisabled
            interactionDisabled={interactionDisabled}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Comment;
