import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useScrollBottom from "../../hooks/useScrollBottom";
import {
  deleteEvent,
  editEvent,
  fetchMoreEvents,
} from "../../redux/eventsSlice";
import Event from "./Event";

function EventsForMe({ profile, setNewEventModalOpened, setEditingEvent }) {
  const dispatch = useDispatch();
  const { isNearBottom } = useScrollBottom();
  const { status, events, hasMoreEvents } = useSelector(
    (state) => state.events
  );
  useEffect(() => {
    if (isNearBottom && hasMoreEvents) dispatch(fetchMoreEvents());
  }, [isNearBottom, dispatch, hasMoreEvents]);
  return (
    <Box className="events" sx={{ width: "100%", mx: "auto" }}>
      {events.map((event, index) => (
        <Event
          key={event._id}
          event={event}
          index={index}
          profile={profile}
          editEvent={(p) => dispatch(editEvent(p))}
          deleteEvent={(id) => dispatch(deleteEvent(id))}
          setNewEventModalOpened={setNewEventModalOpened}
          setEditingEvent={setEditingEvent}
        />
      ))}
      {status === "success" && events.length === 0 && (
        <Paper
          elevation={2}
          sx={{
            my: 1,
            borderRadius: 2,
          }}
        >
          <Typography sx={{ textAlign: "center", my: 1, py: 1 }} variant="h6">
            No event available
          </Typography>
        </Paper>
      )}
      {status === "loading" && (
        <Paper
          elevation={2}
          sx={{
            padding: "1rem",
            my: 1,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Skeleton
              variant="circular"
              width={40}
              height={40}
              sx={{ my: 1 }}
            />
            <Box sx={{ display: "flex", flexFlow: "column" }}>
              <Skeleton
                variant="text"
                width={150}
                height={20}
                sx={{ mt: 1, mx: 1 }}
              />
              <Skeleton variant="text" width={100} height={10} sx={{ mx: 1 }} />
            </Box>
          </Box>
          <Skeleton variant="text" height={10} sx={{ my: 0.5 }} />
          <Skeleton variant="text" width="75%" height={10} sx={{ my: 0.5 }} />
          <Skeleton variant="rectangular" height={300} sx={{ my: 1 }} />
          <Box
            sx={{ mt: 0.5, display: "flex", justifyContent: "space-between" }}
          >
            <Skeleton variant="rounded" width="32%" height={20} />
            <Skeleton variant="rounded" width="32%" height={20} />
            <Skeleton variant="rounded" width="32%" height={20} />
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default EventsForMe;
