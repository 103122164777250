import AddCommentIcon from '@mui/icons-material/AddComment';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function CommentBox({ postComment, replyTo }) {
    const { t } = useTranslation('posts-events');
    const [comment, setComment] = useState('');
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', my: 1, alignItems: 'flex-end' }}>
            <TextField
                sx={{ mr: 0.5 }}
                fullWidth
                id="standard-textarea"
                placeholder={t('comment')}
                multiline
                variant="standard"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
            <IconButton
                onClick={() => {
                    postComment({ comment, replyTo });
                    setComment('');
                }}
                color="info"
            >
                <AddCommentIcon />
            </IconButton>
        </Box>
    );
}

export default CommentBox;
