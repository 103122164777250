import {createSlice } from "@reduxjs/toolkit";

const initialState = {
    ids: [],
};


export const messageReadSlice = createSlice({
  name: "message_reads",
  initialState,
  reducers: {
    setMessageIds: (state, action) => {
      state.ids = action.payload;
    },
  },
});

export default messageReadSlice.reducer;
export const { setMessageIds } = messageReadSlice.actions;
