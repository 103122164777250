import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { reportPost, setAlertMessage } from "../../redux/postsSlice";

export default function ReportPost({
  profile,
  post,
  isReportPostModal,
  setIsReportPostModal,
}) {
  const { t } = useTranslation(["posts-events", "common"]);
  const [reason, setReason] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!reason) {
      return dispatch(
        setAlertMessage({
          message: t("reasonRequired"),
          show: true,
          type: "error",
        })
      );
    }
    dispatch(reportPost({ reason, postID: post._id, reporterID: profile._id }));
    setReason("");
    return setIsReportPostModal(false);
  };

  return (
    <Modal
      aria-labelledby="report-post"
      aria-describedby="Report post"
      open={isReportPostModal}
      onClose={() => setIsReportPostModal(false)}
      closeAfterTransition
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiPaper-root": {
          position: "unset",
          zIndex: 20000,
        },
      }}
    >
      <Zoom in={isReportPostModal}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            position: "absolute",
            top: 0,
            maxWidth: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h4" component="h2" sx={{ mr: 5 }}>
                {t("reportPost")}
              </Typography>
              <IconButton
                onClick={() => setIsReportPostModal(false)}
                sx={{ ml: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              id="standard-textarea"
              placeholder={t("reasonExplanation")}
              multiline
              variant="outlined"
              fullWidth
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{ py: 2 }}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="success"
                type="submit"
                sx={{ borderRadius: 10, m: 0.5 }}
                startIcon={<AddModeratorOutlinedIcon />}
              >
                {t("common:submit")}
              </Button>
            </Box>
          </form>
        </Paper>
      </Zoom>
    </Modal>
  );
}
