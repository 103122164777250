import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';

export default function ProfileImage({ image, handleDeleteImage, handleProfilePicChange, t }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box key={image.key} className="group relative my-2 first-of-type:my-0 last-of-type:my-0">
            <img className="w-full rounded-md" src={image.url} alt={image.url} />
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ position: 'absolute', top: 1, right: 1 }}
                className="group-hover:shadow-sm group-hover:shadow-gray-600"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleDeleteImage(image);
                        setAnchorEl(null);
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('common:delete')}</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleProfilePicChange(image);
                        setAnchorEl(null);
                    }}
                >
                    <ListItemIcon>
                        <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{t('makeProfilePicture')}</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
}
