import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Zoom from '@mui/material/Zoom';
import { useTheme } from '@mui/material/styles';

function SuggestionModal({
    showModal,
    setTextInput,
    handleSubmit,
    handleCloseModal,
    parent,
    textInput,
    t,
}) {
    const theme = useTheme();
    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby="node-suggestion-modal"
            aria-describedby="Modal to suggest new node"
            sx={{
                overflowY: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiPaper-root': {
                    position: 'unset',
                    zIndex: 20000,
                    margin: 'auto',
                },
            }}
        >
            <Zoom in={showModal}>
                <Paper
                    elevation={2}
                    sx={{
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        maxWidth: '600px',
                        width: '100%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 4,
                        p: 3,
                        minWidth: '280px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography variant="h5" component="h2">
                            {t('suggestionModalHeader')}
                            {parent?.name}
                        </Typography>
                        <IconButton onClick={() => handleCloseModal()} sx={{ ml: 'auto' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box textAlign="center" mb={2}>
                        <AddTaskOutlinedIcon
                            sx={{ fontSize: '3rem', color: theme.palette.info.main }}
                        />
                    </Box>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            id="suggestion"
                            label={t('yourSuggestion')}
                            variant="outlined"
                            sx={{ width: '100%' }}
                            value={textInput}
                            onChange={(e) => setTextInput(e.target.value)}
                        />
                        <Box sx={{ display: 'flex', mt: 3 }}>
                            <Button
                                sx={{ m: 0.5, ml: 'auto', borderRadius: 10 }}
                                variant="outlined"
                                color="error"
                                onClick={handleCloseModal}
                                type="reset"
                            >
                                {t('common:close')}
                            </Button>
                            <Button
                                sx={{ m: 0.5, borderRadius: 10 }}
                                color="primary"
                                variant="outlined"
                                type="submit"
                            >
                                {t('common:submit')}
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Zoom>
        </Modal>
    );
}

export default SuggestionModal;
