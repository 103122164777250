import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Popover from "@mui/material/Popover";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addCommitment } from "../../redux/eventsSlice";
import Utils from "../../utils/Utils";

const { formatNumber } = Utils;

export default function Commitment({ event, profile, interactionDisabled }) {
  const { t } = useTranslation(["posts-events"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverControl = useRef(null);
  const handlePopoverOpen = () => {
    setAnchorEl(popoverControl.current);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { commitmentStatus } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const [commitment, setCommitment] = useState(
    event.userCommitment || "not-interested"
  );
  const updateCommitment = (type) => {
    dispatch(
      addCommitment({
        type,
        eventID: event._id,
        userID: profile._id,
      })
    );
  };
  useEffect(() => {
    setCommitment(event.userCommitment);
  }, [event.userCommitment]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography fontSize="medium">
        {event.commitmentsCount.interested > 0 &&
          `${formatNumber(event.commitmentsCount.interested)} ${t(
            "interestedLower"
          )}`}{" "}
        {event.commitmentsCount.interested > 0 &&
          event.commitmentsCount.going > 0 &&
          "· "}
        {event.commitmentsCount.going > 0 &&
          `${formatNumber(event.commitmentsCount.going)} ${t("goingLower")}`}
      </Typography>
      <Box
        ref={popoverControl}
        aria-owns={open ? "commitment-popover" : undefined}
        aria-haspopup="true"
        sx={{ my: 0.5 }}
      >
        <Button
          size="small"
          color={
            event.userCommitment && event.userCommitment !== "not-interested"
              ? "primary"
              : "inherit"
          }
          sx={{
            "& svg, & img": {
              mx: 0.25,
            },
            bgcolor: theme.palette.action.hover,
            px: 1,
          }}
          onClick={() => {
            handlePopoverOpen();
          }}
          disabled={
            interactionDisabled ||
            (commitmentStatus !== "idle" && commitmentStatus !== "success")
          }
        >
          {event.userCommitment === "interested" && (
            <>
              <StarRoundedIcon />
              <Typography>{t("interested")}</Typography>
              <ExpandMoreIcon />
            </>
          )}
          {event.userCommitment === "going" && (
            <>
              <CheckCircleIcon />
              <Typography>{t("going")}</Typography>
              <ExpandMoreIcon />
            </>
          )}
          {(!event.userCommitment ||
            event.userCommitment === "not-interested") && (
            <>
              <StarOutlineRoundedIcon />
              <Typography>{t("interested")}</Typography>
            </>
          )}
        </Button>
        <Popover
          id="commitment-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          sx={{
            borderRadius: 2,
          }}
        >
          <Box sx={{ p: 2 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="event-commitment-radio-button"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  onClick={handlePopoverClose}
                  value="interested"
                  control={<Radio />}
                  label={t("interested")}
                  checked={commitment === "interested"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCommitment("interested");
                      updateCommitment("interested");
                    }
                  }}
                />
                <FormControlLabel
                  onClick={handlePopoverClose}
                  value="going"
                  control={<Radio />}
                  label={t("going")}
                  checked={commitment === "going"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCommitment("going");
                      updateCommitment("going");
                    }
                  }}
                />
                <FormControlLabel
                  onClick={handlePopoverClose}
                  value="not-interested"
                  control={<Radio />}
                  label={t("notInterested")}
                  checked={!commitment || commitment === "not-interested"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCommitment("not-interested");
                      updateCommitment("not-interested");
                    }
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
