import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
    status: "",
    conversations: [],
};


export const conversation = createAsyncThunk(
  "chat/conversation",
  async (userId) => {
    const response = await fetch(`${serverURL}/chat/conversations/${userId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const profile = await response.json();
    return profile;
  }
);


export const conversationSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setConversations: (state, action) => {
      state.conversations = action.payload.conversations;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(conversation.fulfilled, (state, action) => {
      state.conversations = action.payload.data;
      state.status = "success";
    });
    builder.addCase(conversation.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default conversationSlice.reducer;
export const { setConversations } = conversationSlice.actions;
