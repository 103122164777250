import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import checkIcon from "../../assets/check.svg";
import clapIcon from "../../assets/clap.svg";
import interestingIcon from "../../assets/interesting.svg";
import { addReaction } from "../../redux/eventsSlice";
import Utils from "../../utils/Utils";
import AlertElem from "../Alert";
import Reaction from "../post/Reaction";

const { formatNumber } = Utils;

function ReactionAndComments({
  event,
  profile,
  setIsCommentSectionOpened,
  interactionDisabled,
}) {
  const { t } = useTranslation(["posts-events"]);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const dispatch = useDispatch();
  const reactToEvent = (type) => {
    dispatch(addReaction({ userID: profile._id, eventID: event._id, type }));
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 0.5 }}>
        {!isNaN(event.reactionCount) && event.reactionCount > 0 && (
          <Button
            sx={{ mr: "auto" }}
            color="inherit"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {`${formatNumber(event.reactionCount)} ${t("reactions")}`}
          </Button>
        )}
        {!isNaN(event.reactionCount) && event.reactionCount > 0 && (
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box
              sx={{
                p: 1,
                "& > div": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  "& img": {
                    width: "2rem",
                  },
                },
              }}
            >
              {event.reactionsByTypes?.check > 0 && (
                <Box>
                  <img src={checkIcon} alt={t("check")} />
                  <Typography ml={2} mr={1}>
                    {formatNumber(event.reactionsByTypes.check)}
                  </Typography>
                </Box>
              )}
              {event.reactionsByTypes?.clap > 0 && (
                <Box>
                  <img src={clapIcon} alt={t("clap")} />
                  <Typography ml={2} mr={1}>
                    {formatNumber(event.reactionsByTypes.clap)}
                  </Typography>
                </Box>
              )}
              {event.reactionsByTypes?.interesting > 0 && (
                <Box>
                  <img src={interestingIcon} alt={t("interesting")} />
                  <Typography ml={2} mr={1}>
                    {formatNumber(event.reactionsByTypes.interesting)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Popover>
        )}
        {!isNaN(event.commentCount) && event.commentCount > 0 && (
          <Button
            sx={{ ml: "auto" }}
            color="error"
            onClick={() => setIsCommentSectionOpened((prev) => !prev)}
          >
            {`${formatNumber(event.commentCount)} ${t("comments")}`}
          </Button>
        )}
      </Box>
      <hr />
      {!interactionDisabled && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexFlow: "row",
          }}
        >
          <Box>
            <Reaction reaction={event.userReaction} react={reactToEvent} />
          </Box>
          <Box>
            <Button
              onClick={() => {
                setIsCommentSectionOpened((prev) => !prev);
              }}
              color="inherit"
              size="small"
              sx={{
                "& svg": {
                  height: "1.75rem",
                  mx: 0.25,
                },
                width: "100%",
              }}
            >
              <CommentOutlinedIcon />
              <Typography>{t("comment")}</Typography>
            </Button>
          </Box>
        </Box>
      )}
      <AlertElem
        isAlertMessage={alert.show}
        alertMessage={alert.message}
        alertMessageType={alert.type}
        onClose={() => setAlert({ show: false, message: "", type: "" })}
      />
    </Box>
  );
}

export default ReactionAndComments;
