import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentReaction,
  deleteComment,
  fetchComments,
  newComment,
  setAlertMessage,
} from "../../redux/eventCommentsSlice";
import Alert from "../Alert";
import Loader from "../Loader";
import Comment from "../post/Comment";
import CommentBox from "../post/CommentBox";

function CommentSection({ event, profile, interactionDisabled }) {
  const dispatch = useDispatch();
  const {
    eventComments: posts,
    alertMessage,
    isAlertMessage,
    alertMessageType,
  } = useSelector((state) => state.eventComments);
  useEffect(() => {
    dispatch(fetchComments({ eventID: event._id }));
  }, [dispatch, event._id]);

  const postComment = ({ comment, replyTo }) => {
    const commentObj = {
      comment,
      userID: profile._id,
      eventID: event._id,
      replyTo,
    };
    dispatch(newComment(commentObj));
  };
  const addReactionToComment = ({ comment, type }) => {
    dispatch(addCommentReaction({ comment, type, userID: profile._id }));
  };

  // Check if the posts for the current eventID have been loaded
  const postComments = posts[event._id]?.comments;
  const postStatus = postComments ? postComments.status : "loading";

  return (
    <Box>
      {!interactionDisabled && <CommentBox postComment={postComment} />}
      {postStatus === "loading" ? (
        <Loader />
      ) : (
        <Box sx={{ pt: 1 }}>
          {postComments.map((comment) => (
            <Comment
              key={comment._id}
              comment={comment}
              profile={profile}
              postComment={postComment}
              addReactionToComment={addReactionToComment}
              deleteComment={(c) => dispatch(deleteComment(c))}
              interactionDisabled={interactionDisabled}
            />
          ))}
        </Box>
      )}
      <Alert
        isAlertMessage={isAlertMessage}
        alertMessage={alertMessage}
        alertMessageType={alertMessageType}
        onClose={() =>
          dispatch(setAlertMessage({ show: false, message: "", type: "" }))
        }
      />
    </Box>
  );
}

export default CommentSection;
