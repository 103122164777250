import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/material';


function CuModel(props,) {
  const { onClose,open,children,class_} = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} className={class_}>
        <Box>
           {children} 
        </Box>
    </Dialog>
  );
}

export default CuModel