import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import checkIcon from '../../assets/check.svg';
import clapIcon from '../../assets/clap.svg';
import interestingIcon from '../../assets/interesting.svg';

let ti;

function Reaction({ reaction, react, type }) {
    const { t } = useTranslation(['posts-events']);
    const [anchorEl, setAnchorEl] = useState(null);
    const hoverRef = useRef(null);
    const handlePopoverOpen = () => {
        ti = setTimeout(() => {
            setAnchorEl(hoverRef.current);
        }, 500);
    };
    const handlePopoverClose = () => {
        clearTimeout(ti);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <Box
            ref={hoverRef}
            aria-owns={open ? 'reaction-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <Button
                size="small"
                color="inherit"
                sx={{
                    '& svg, & img': {
                        height: type === 'comment-reaction' ? '1.4rem' : '1.75rem',
                        mx: 0.25,
                    },
                    width: type === 'comment-reaction' ? 'unset' : '100%',
                    margin: type === 'comment-reaction' ? '0.1rem' : null,
                }}
                onClick={() => {
                    if (reaction) react();
                    else react('check');
                }}
            >
                {reaction ? (
                    <>
                        <img src={`${reaction}.svg`} alt={t(reaction)} />
                        <Typography
                            sx={{ textTransform: 'capitalize' }}
                            fontSize={type === 'comment-reaction' ? 'small' : null}
                        >
                            {t(reaction)}
                        </Typography>
                    </>
                ) : (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" id="emoji" viewBox="0 0 72 72">
                            <g id="line">
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    strokeWidth="2"
                                    d="m10.5 39.76 17.42 17.44 33.58-33.89-8.013-8.013-25.71 25.71-9.26-9.26z"
                                />
                            </g>
                        </svg>
                        <Typography fontSize={type === 'comment-reaction' ? 'small' : null}>
                            {t('check')}
                        </Typography>
                    </>
                )}
            </Button>
            <Popover
                id="reaction-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
                sx={{
                    background: 'transparent',
                    borderRadius: 20,
                    overflow: 'hidden',
                    "[class*='MuiPaper-root-MuiPopover-paper']": {
                        borderRadius: 100,
                    },
                }}
            >
                <Box
                    sx={{
                        borderRadius: 20,
                        overflow: 'hidden',
                    }}
                    onMouseLeave={() => {
                        setAnchorEl(null);
                    }}
                >
                    <Tooltip title={t('check')} placement="top">
                        <Button
                            onClick={() => {
                                if (reaction === 'check') react();
                                else react('check');
                                handlePopoverClose();
                            }}
                            sx={{
                                padding: '0.25rem 0.3rem',
                                '& img': {
                                    maxWidth: '2.5rem',
                                },
                            }}
                        >
                            <img src={checkIcon} alt={t('check')} />
                        </Button>
                    </Tooltip>
                    <Tooltip title={t('clap')} placement="top">
                        <Button
                            onClick={() => {
                                if (reaction === 'clap') react();
                                else react('clap');
                                handlePopoverClose();
                            }}
                            sx={{
                                padding: '0.25rem 0.3rem',
                                '& img': {
                                    maxWidth: '2.5rem',
                                },
                            }}
                        >
                            <img src={clapIcon} alt={t('clap')} />
                        </Button>
                    </Tooltip>
                    <Tooltip title={t('interesting')} placement="top">
                        <Button
                            onClick={() => {
                                if (reaction === 'interesting') react();
                                else react('interesting');
                                handlePopoverClose();
                            }}
                            sx={{
                                padding: '0.25rem 0.3rem',
                                '& img': {
                                    maxWidth: '2.5rem',
                                },
                            }}
                        >
                            <img src={interestingIcon} alt={t('interesting')} />
                        </Button>
                    </Tooltip>
                </Box>
            </Popover>
        </Box>
    );
}

export default Reaction;
