import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Loader from "./Loader";

export default function PrivateRoute() {
  const location = useLocation();
  const { status, isLoggedIn } = useSelector((state) => state.auth);
  if (status === "loading") return <Loader />;
  if (status === "success" && isLoggedIn) {
    return <Outlet />;
  }
  if (status !== "idle") return <Navigate to="/" state={{ from: location }} />;
}
