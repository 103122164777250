import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Utils from "../../utils/Utils";

const { serverURL } = Utils;

function EmailVerifyWarning({ userEmail }) {
  const { t } = useTranslation(["auth"]);
  const [secondsRemaining, setSecondsRemaining] = useState(30); // Adjust for desired resend interval
  const [isResendEnabled, setIsResendEnabled] = useState(true);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setIsEmailSent(false);
        setIsResendEnabled(true);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [secondsRemaining]); // Only run when secondsRemaining changes

  const handleResendClick = async () => {
    setIsSending(true);
    const result = await fetch(`${serverURL}/auth/resend-email`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: userEmail }),
      credentials: "include",
    });
    setIsSending(false);
    if (!result.ok) {
      toast.error(await result.text());
      setIsEmailSent(false);
      return setIsResendEnabled(true);
    }
    setIsEmailSent(true);
    setSecondsRemaining(30); // Reset timer after resend
    setIsResendEnabled(false);
  };

  const formattedSeconds = secondsRemaining.toString().padStart(2, "0");

  return (
    <Alert severity="info" variant="outlined">
      <AlertTitle>{t("verifyYourEmail")}</AlertTitle>
      <Typography variant="body2">
        {t("emailSent")} {userEmail} {t("followEmailLink")}
      </Typography>
      <Typography className="py-1" variant="body2">
        {t("didntReceiveEmail")}
      </Typography>
      <Button
        disabled={isEmailSent || isSending}
        variant="contained"
        onClick={handleResendClick}
      >
        {isSending
          ? t("sending")
          : isEmailSent
          ? t("emailSentSuccess")
          : t("resendEmail")}
      </Button>
      {!isResendEnabled && (
        <Typography variant="body2">
          {t("youCanResendIn")} {formattedSeconds} {t("seconds")}
        </Typography>
      )}
    </Alert>
  );
}

export default EmailVerifyWarning;
