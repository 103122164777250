import ContactMailIcon from '@mui/icons-material/ContactMail';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlertElem from '../components/Alert';
import EventCreationModal from '../components/event/EventCreationModal';
import EventsForMe from '../components/event/EventsForMe';
import MyEvents from '../components/event/MyEvents';
import { editEvent, fetchEvents, setAlertMessage } from '../redux/eventsSlice';

export default function Events() {
    const { t } = useTranslation(['posts-events']);
    const theme = useTheme();
    const [newEventModalOpened, setNewEventModalOpened] = useState(false);
    const { profile } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { isAlertMessage, alertMessage, alertMessageType } = useSelector((state) => state.events);
    useEffect(() => {
        dispatch(fetchEvents());
    }, [dispatch]);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState(Number(searchParams.get('tab')) || 0);
    const [editingEvent, setEditingEvent] = useState({});
    const updateTab = (n) => {
        searchParams.set('tab', n);
        navigate(`?${searchParams.toString()}`, { replace: true });
        setTab(n);
    };
    return (
        <Box maxWidth={theme.breakpoints.values.sm} sx={{ mx: 'auto', flex: 1 }}>
            <Tabs value={tab} onChange={(e, n) => updateTab(n)} aria-label="events tabs" centered>
                <Tab icon={<ContactMailIcon />} iconPosition="start" label={t('eventsForMe')} />
                <Tab icon={<PlaylistAddIcon />} iconPosition="start" label={t('myEvents')} />
            </Tabs>
            {tab === 0 && (
                <EventsForMe
                    profile={profile}
                    newEventModalOpened={newEventModalOpened}
                    setNewEventModalOpened={setNewEventModalOpened}
                    setEditingEvent={setEditingEvent}
                />
            )}
            {tab === 1 && (
                <MyEvents
                    oldEvent={editingEvent}
                    profile={profile}
                    newEventModalOpened={newEventModalOpened}
                    setNewEventModalOpened={setNewEventModalOpened}
                    setEditingEvent={setEditingEvent}
                />
            )}
            <EventCreationModal
                oldEvent={editingEvent}
                editEvent={editEvent}
                setEditingEvent={setEditingEvent}
                profile={profile}
                newEventModalOpened={newEventModalOpened}
                setNewEventModalOpened={setNewEventModalOpened}
            />
            <AlertElem
                isAlertMessage={isAlertMessage}
                alertMessage={alertMessage}
                alertMessageType={alertMessageType}
                onClose={() => dispatch(setAlertMessage({ show: false, message: '', type: '' }))}
            />
        </Box>
    );
}
