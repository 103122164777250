import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { editSubscription, subscribeToInterest } from "../redux/authSlice";
import Utils from "../utils/Utils";
import LocationAndRange from "./LocationAndRange";

const { pathSeparator } = Utils;

export default function InterestSubscription({
  profile,
  interest,
  isInterestSub,
  setIsInterestSub,
  subscription,
}) {
  const { t } = useTranslation(["directory", "common"]);
  const [isPrivate, setIsPrivate] = useState(subscription?.isPrivate || false);
  const [location, setLocation] = useState(
    subscription?.location || profile.defaultLocation || {}
  );
  const [range, setRange] = useState(
    subscription?.range || profile.interestRange || null
  );
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (subscription && subscription._id) {
      dispatch(
        editSubscription({
          ...subscription,
          range,
          isPrivate,
          location,
        })
      );
    } else
      dispatch(
        subscribeToInterest({
          location,
          range,
          id: interest._id,
          path: interest.path,
          isPrivate,
        })
      );
    setIsInterestSub(false);
  };

  return (
    <Modal
      aria-labelledby="subscribe-to-interest"
      aria-describedby="Subscribe to an interest"
      open={isInterestSub}
      onClose={() => setIsInterestSub(false)}
      closeAfterTransition
      disableScrollLock
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiPaper-root": {
          position: "unset",
          zIndex: 20000,
        },
      }}
    >
      <Zoom in={isInterestSub}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            position: "absolute",
            top: 0,
            maxWidth: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            minWidth: "280px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography variant="h4" component="h2" sx={{ mr: 5 }}>
                {t("subscribeTo")}{" "}
                {interest.path.split(pathSeparator).filter(Boolean).at(-1)}
              </Typography>
              <IconButton
                onClick={() => setIsInterestSub(false)}
                sx={{ ml: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <LocationAndRange
              interestRange={range}
              setInterestRange={setRange}
              location={location}
              setLocation={setLocation}
            />
            <Tooltip title={t("makePrivateTooltip")} placement="top">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                  />
                }
                label={t("makePrivate")}
              />
            </Tooltip>
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="success"
                type="submit"
                sx={{ borderRadius: 10, m: 0.5 }}
                onClick={() => setIsInterestSub(true)}
                startIcon={<PlaylistAddOutlinedIcon />}
              >
                {t("common:subscribe")}
              </Button>
            </Box>
          </form>
        </Paper>
      </Zoom>
    </Modal>
  );
}
