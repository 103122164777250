import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Help() {
  const { t } = useTranslation(["help", "common"]);
  const { profile } = useSelector((state) => state.auth);
  const [message, setMessage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    alert(
      JSON.stringify(
        {
          email: profile.email,
          subject: `YKOP support request from ${profile.username}`,
          body: message,
        },
        null,
        2
      )
    );
  };
  return (
    <Box className="w-full" alignSelf="center">
      <Paper
        className="max-w-[600px] w-full mx-auto"
        elevation={3}
        sx={{
          padding: "2rem 1rem",
          my: 2,
          borderRadius: 3,
        }}
      >
        <Box textAlign="center" marginBottom="1rem">
          <img src="logo.png" alt="Logo" />
        </Box>
        <Typography variant="h4" my={3} textAlign="center">
          {t("getHelp")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-textarea"
            label={t("yourMessage")}
            placeholder={t("whatCouldWeImprove")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            fullWidth
          />
          <Box sx={{ display: "flex" }}>
            <Button
              disabled={message.trimStart().length === 0}
              sx={{
                margin: "1rem auto",
                padding: "0.5rem 1.5rem",
                borderRadius: "100px",
                textTransform: "none",
              }}
              variant="contained"
              color="success"
              type="submit"
            >
              {t("common:submit")}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}
