import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal } from "@mui/material";
import Paper from "@mui/material/Paper";
import Zoom from "@mui/material/Zoom";

export default function ModalMessage({
  showModal,
  handleCloseModal,
  children,
}) {
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="node-suggestion-modal"
      aria-describedby="Modal to suggest new node"
      sx={{
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiPaper-root": {
          position: "unset",
          zIndex: 20000,
          margin: "auto",
        },
      }}
    >
      <Zoom in={showModal}>
        <Paper
          elevation={2}
          sx={{
            display: "block",
            position: "absolute",
            top: 0,
            // maxWidth: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <div className="flex justify-end">
            <IconButton className="ml-auto" onClick={() => handleCloseModal()}>
              <CloseIcon />
            </IconButton>
          </div>
          {children}
        </Paper>
      </Zoom>
    </Modal>
  );
}
