import { Avatar, Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteImage, setProfilePic, uploadImages } from '../../redux/authSlice';
import FileUploader from '../commons/FileUploader';
import ProfileImage from './ProfileImage';

export default function ProfileImages({ profile, t }) {
    const dispatch = useDispatch();
    const [imageUploaderOpen, setImageUploaderOpen] = useState(false);

    const { images, profilePic } = profile;

    const onFileSelect = (files) => {
        const formData = new FormData();
        if (files.length > 0) {
            files.forEach((file) => {
                formData.append('images', file, file.name);
            });
            dispatch(uploadImages(formData));
        }
    };

    const handleDeleteImage = (image) => {
        dispatch(deleteImage(image));
    };

    const handleProfilePicChange = (image) => {
        dispatch(setProfilePic(image));
    };
    return (
        <Box>
            <Avatar
                src={profilePic?.url || null}
                alt={profile?.username || t('defaultProfile')}
                sx={{ width: '150px', height: '150px', m: 'auto' }}
            />
            {imageUploaderOpen && (
                <FileUploader
                    onFileSelect={onFileSelect}
                    acceptedFileTypes={{ 'image/*': [] }}
                    instructionText={t('common:dragAndDropImage')}
                />
            )}
            <Box className="flex justify-between">
                <Typography variant="h5">{t('yourUploads')}</Typography>
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => setImageUploaderOpen((prev) => !prev)}
                >
                    {t('common:upload')}
                </Button>
            </Box>
            {images?.length > 0 && (
                <Box className="my-2 columns-2 gap-2">
                    {images.map((image) => (
                        <ProfileImage
                            key={image.key}
                            image={image}
                            handleDeleteImage={handleDeleteImage}
                            handleProfilePicChange={handleProfilePicChange}
                            t={t}
                        />
                    ))}
                </Box>
            )}
            {!images?.length && (
                <Typography className="text-center pb-2">{t('youHaveNoUploads')}</Typography>
            )}
        </Box>
    );
}
