import { useEffect, useState } from 'react';

export default function useScrollBottom() {
    const [isBottom, setIsBottom] = useState(false);
    const [isNearBottom, setIsNearBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const bottom =
                window.innerHeight + document.documentElement.scrollTop ===
                document.documentElement.offsetHeight;
            setIsBottom(bottom);

            const nearBottom =
                window.innerHeight + document.documentElement.scrollTop >
                document.documentElement.offsetHeight - 100;
            setIsNearBottom(nearBottom);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return {
        isBottom,
        isNearBottom,
    };
}
