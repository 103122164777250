import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from "../i18n";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
  status: "idle",
  isLoggedIn: false,
  verificationStatus: null,
  passwordResetStatus: null,
  changeEmailStatus: null,
  profile: null,
  isAlertMessage: false,
  alertMessage: "",
  alertMessageType: "",
};

export const checkAuth = createAsyncThunk("auth/checkAuth", async () => {
  const response = await fetch(`${serverURL}/auth/check`, {
    method: "GET",
    credentials: "include",
  });
  if (!response.ok) throw new Error(i18n.t("notLoggedIn"));
  const profile = await response.json();
  return profile;
});

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData) => {
    const response = await fetch(`${serverURL}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const profile = await response.json();
    return profile;
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData) => {
    const response = await fetch(`${serverURL}/auth/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const profile = await response.json();
    return profile;
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ token, signal }) => {
    const response = await fetch(`${serverURL}/auth/verify-email`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
      credentials: "include",
      signal,
    });
    if (!response.ok) throw new Error(await response.text());
    const result = await response.text();
    return result;
  }
);

export const editProfile = createAsyncThunk(
  "auth/editProfile",
  async (updates) => {
    const response = await fetch(`${serverURL}/user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updates),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const profile = await response.json();
    return profile;
  }
);

export const uploadImages = createAsyncThunk(
  "auth/uploadImages",
  async (formData) => {
    const response = await fetch(`${serverURL}/user/upload-images`, {
      method: "POST",
      body: formData,
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const images = await response.json();
    return images;
  }
);

export const setProfilePic = createAsyncThunk(
  "auth/setProfilePic",
  async (image) => {
    const response = await fetch(`${serverURL}/user/set-profile-pic`, {
      method: "POST",
      body: JSON.stringify(image),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    return image;
  }
);

export const deleteImage = createAsyncThunk(
  "auth/deleteImage",
  async (image) => {
    const response = await fetch(`${serverURL}/user/delete-image`, {
      method: "POST",
      body: JSON.stringify(image),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const images = await response.json();
    return images;
  }
);

export const subscribeToInterest = createAsyncThunk(
  "auth/subscribeToInterest",
  async (subscription) => {
    const response = await fetch(`${serverURL}/user/add-interest`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(subscription),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const interests = await response.json();
    return interests;
  }
);

export const editSubscription = createAsyncThunk(
  "auth/editSubscription",
  async (subscription) => {
    const response = await fetch(`${serverURL}/user/edit-interest`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(subscription),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const interests = await response.json();
    return interests;
  }
);

export const removeSubscription = createAsyncThunk(
  "auth/removeSubscription",
  async ({ id }) => {
    const response = await fetch(`${serverURL}/user/remove-interest`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id }),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const interests = await response.json();
    return interests;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await fetch(`${serverURL}/auth/logout`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });
  if (!response.ok) throw new Error(await response.text());
  return response.status;
});

export const resetPasswordRequest = createAsyncThunk(
  "auth/resetPasswordRequest",
  async ({ email }) => {
    const response = await fetch(`${serverURL}/auth/reset-password-request`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ email }),
    });
    if (!response.ok) throw new Error(await response.text());
    return response.status;
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data) => {
    const response = await fetch(`${serverURL}/auth/reset-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error(await response.text());
    return response.status;
  }
);

export const changeEmail = createAsyncThunk(
  "auth/changeEmail",
  async (data) => {
    const response = await fetch(`${serverURL}/auth/change-email`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(data),
    });
    if (!response.ok) throw new Error(await response.text());
    return response.status;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
    },
    clearAlertMessage: (state) => {
      state.alertMessage = "";
      state.alertMessageType = "";
      state.isAlertMessage = false;
    },
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload.message;
      state.alertMessageType = action.payload.type;
      state.isAlertMessage = action.payload.show;
    },
    clearProfile: (state) => {
      state.profile = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkAuth.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(checkAuth.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isLoggedIn = true;
      state.status = "success";
    });
    builder.addCase(checkAuth.rejected, (state) => {
      state.isLoggedIn = false;
      state.status = "failed";
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isLoggedIn = true;
      state.status = "success";
      state.alertMessage = i18n.t("updateSuccessful");
      state.alertMessageType = "success";
      state.isAlertMessage = true;
    });
    builder.addCase(editProfile.rejected, (state, action) => {
      toast.error(`${i18n.t("updateFailed")} ${action.error.message}`);
    });
    builder.addCase(uploadImages.fulfilled, (state, action) => {
      state.profile.images = action.payload;
    });
    builder.addCase(uploadImages.rejected, () => {
      toast.error(i18n.t("uploadFailed"));
    });
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      state.profile.images = action.payload;
    });
    builder.addCase(deleteImage.rejected, () => {
      toast.error(i18n.t("updateFailed"));
    });
    builder.addCase(setProfilePic.fulfilled, (state, action) => {
      state.profile.profilePic = action.payload;
    });
    builder.addCase(setProfilePic.rejected, () => {
      toast.error(i18n.t("updateFailed"));
    });
    builder.addCase(loginUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      localStorage.setItem("user_details", JSON.stringify(action.payload));
      state.profile = action.payload;
      state.isLoggedIn = true;
      state.status = "success";
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.alertMessageType = "error";
      state.alertMessage = `${i18n.t("loginFailed")} ${action.error.message}`;
      state.isLoggedIn = false;
      state.isAlertMessage = true;
      state.status = "failed";
    });
    builder.addCase(registerUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(registerUser.fulfilled, (state) => {
      // state.profile = action.payload;
      state.status = "success";
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      toast.error(`${i18n.t("registrationFailed")} ${action.error.message}`);
      state.status = "failed";
    });
    builder.addCase(verifyEmail.pending, (state) => {
      state.verificationStatus = "loading";
    });
    builder.addCase(verifyEmail.fulfilled, (state) => {
      state.verificationStatus = "verified";
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      if (action.error.name === "AbortError") return;
      toast.error(`${i18n.t("verificationFailed")} ${action.error.message}`);
      state.verificationStatus = "failed";
    });
    builder.addCase(resetPasswordRequest.pending, (state) => {
      state.passwordResetStatus = "loading";
    });
    builder.addCase(resetPasswordRequest.fulfilled, (state) => {
      state.passwordResetStatus = "success";
    });
    builder.addCase(resetPasswordRequest.rejected, (state) => {
      state.passwordResetStatus = "error";
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.passwordResetStatus = "loading";
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.passwordResetStatus = "success";
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.passwordResetStatus = "error";
    });
    builder.addCase(changeEmail.pending, (state) => {
      state.changeEmailStatus = "loading";
    });
    builder.addCase(changeEmail.fulfilled, (state) => {
      state.changeEmailStatus = "success";
    });
    builder.addCase(changeEmail.rejected, (state) => {
      state.changeEmailStatus = "error";
    });
    builder.addCase(logout.fulfilled, (state) => {
      localStorage.clear();
      state.profile = {};
      state.alertMessageType = "success";
      state.alertMessage = i18n.t("logoutSuccessful");
      state.isLoggedIn = false;
      state.isAlertMessage = true;
      state.status = "success";
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.alertMessageType = "error";
      state.alertMessage = `${i18n.t("logoutFailed")} ${action.error.message}`;
      state.isAlertMessage = true;
      state.isLoggedIn = true;
      state.status = "failed";
    });
    builder.addCase(subscribeToInterest.fulfilled, (state, action) => {
      state.profile.subscribedInterests = action.payload;
    });
    builder.addCase(subscribeToInterest.rejected, (state, action) => {
      state.alertMessageType = "error";
      state.alertMessage = `${i18n.t("subscriptionFailed")} ${
        action.error.message
      }`;
      state.isAlertMessage = true;
    });
    builder.addCase(editSubscription.fulfilled, (state, action) => {
      state.profile.subscribedInterests = action.payload;
    });
    builder.addCase(editSubscription.rejected, (state, action) => {
      state.alertMessageType = "error";
      state.alertMessage = `${i18n.t("editingFailed")} ${action.error.message}`;
      state.isAlertMessage = true;
    });
    builder.addCase(removeSubscription.fulfilled, (state, action) => {
      state.profile.subscribedInterests = action.payload;
    });
    builder.addCase(removeSubscription.rejected, (state, action) => {
      state.alertMessageType = "error";
      state.alertMessage = `${i18n.t("unsubscribeFailed")} ${
        action.error.message
      }`;
      state.isAlertMessage = true;
    });
  },
});

export default authSlice.reducer;
export const { setAuth, clearAlertMessage, setAlertMessage, clearProfile } =
  authSlice.actions;
