import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SignUpSuccess() {
    const { t } = useTranslation(['auth', 'common']);
    return (
        <Box className="w-full" alignSelf="center">
            <Paper
                className="max-w-[600px] w-full mx-auto"
                elevation={3}
                sx={{
                    padding: '2rem 1rem',
                    my: 2,
                    borderRadius: 3,
                }}
            >
                <Box className="flex justify-center" marginBottom="1rem">
                    <img src="logo.png" alt="Logo" />
                </Box>
                <Typography
                    className="text-green-600"
                    variant="h4"
                    my={3}
                    color="success"
                    textAlign="center"
                >
                    {t('signupSuccessful')}
                </Typography>
                <Typography>
                    {t('emailSent')} {t('followEmailLink')}
                </Typography>
            </Paper>
        </Box>
    );
}
