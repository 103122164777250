import { SvgIcon } from '@mui/material';

export default function FeedsIcon({ sx }) {
    return (
        <SvgIcon sx={sx}>
            <svg
                width="100"
                height="100"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="3.5"
                    y="3.5"
                    width="93"
                    height="93"
                    rx="10.5"
                    stroke="currentColor"
                    strokeWidth="7"
                />
                <path
                    d="M18.9372 20H81.731"
                    stroke="currentColor"
                    strokeWidth="7"
                    strokeLinecap="round"
                />
                <path
                    d="M18.9372 39.6816H81.731"
                    stroke="currentColor"
                    strokeWidth="7"
                    strokeLinecap="round"
                />
                <path
                    d="M18.9372 59.3632H81.731"
                    stroke="currentColor"
                    strokeWidth="7"
                    strokeLinecap="round"
                />
                <path
                    d="M18 79.0449H80.7937"
                    stroke="currentColor"
                    strokeWidth="7"
                    strokeLinecap="round"
                />
            </svg>
        </SvgIcon>
    );
}
