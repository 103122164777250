import { Box, Paper, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import AlertElem from "../components/Alert";
import Event from "../components/event/Event";
import EventCreationModal from "../components/event/EventCreationModal";
import Post from "../components/post/Post";
import PostCreationModal from "../components/post/PostCreationModal";
import useScrollBottom from "../hooks/useScrollBottom";
import {
  deleteFeedEvent,
  deleteFeedPost,
  editFeedEvent,
  editFeedPost,
  fetchEvents,
  fetchMoreEvents,
  fetchMorePosts,
  fetchPosts,
  setAlertMessage,
} from "../redux/feedsSlice";

export default function PublicFeeds() {
  const { t } = useTranslation(["posts-events"]);
  const dispatch = useDispatch();
  const { parent, path } = useParams();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("user");
  const languagePreference = navigator.language || navigator.userLanguage;
  const {
    feedPosts,
    feedEvents,
    postsStatus,
    eventsStatus,
    hasMorePosts,
    hasMoreEvents,
    isAlertMessage,
    alertMessage,
    alertMessageType,
  } = useSelector((state) => state.feeds);
  const { profile } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(fetchPosts({ parent, path, languagePreference, userId }));
    dispatch(fetchEvents({ parent, path, languagePreference, userId }));
  }, [dispatch, parent, path, languagePreference, userId]);
  const [feeds, setFeeds] = useState([]);
  useEffect(() => {
    setFeeds(
      [...feedPosts, ...feedEvents].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    );
  }, [feedEvents, feedPosts]);
  const { isNearBottom } = useScrollBottom();
  useEffect(() => {
    if (isNearBottom && hasMorePosts)
      dispatch(fetchMorePosts({ parent, path, languagePreference }));
    if (isNearBottom && hasMoreEvents)
      dispatch(fetchMoreEvents({ parent, path, languagePreference }));
  }, [
    isNearBottom,
    dispatch,
    hasMoreEvents,
    hasMorePosts,
    parent,
    path,
    languagePreference,
  ]);
  const [editingPost, setEditingPost] = useState({});
  const [newPostModalOpened, setNewPostModalOpened] = useState(false);
  const [editingEvent, setEditingEvent] = useState({});
  const [newEventModalOpened, setNewEventModalOpened] = useState(false);
  return (
    <Box
      className="max-w-[600px] w-full mx-auto"
      sx={{
        flex: 1,
      }}
      mx="auto"
    >
      <Box
        className="feeds"
        sx={{ width: "100%", mx: "auto", position: "relative" }}
      >
        {feeds.map((post, index) =>
          post.postType === "event" ? (
            <Event
              key={post._id}
              event={post}
              index={index}
              profile={profile}
              interactionDisabled
              pageType="public"
              editEvent={(p) => dispatch(editFeedEvent(p))}
              deleteEvent={(id) => dispatch(deleteFeedEvent(id))}
              setNewEventModalOpened={setNewEventModalOpened}
              setEditingEvent={setEditingEvent}
            />
          ) : (
            <Post
              key={post._id}
              post={post}
              index={index}
              profile={profile}
              interactionDisabled
              pageType="public"
              editFeedPost={(p) => dispatch(editFeedPost(p))}
              deletePost={(id) => dispatch(deleteFeedPost(id))}
              setNewPostModalOpened={setNewPostModalOpened}
              setEditingPost={setEditingPost}
            />
          )
        )}
        {postsStatus === "success" &&
          eventsStatus === "success" &&
          feeds.length === 0 && (
            <Paper
              elevation={2}
              sx={{
                my: 1,
                borderRadius: 2,
              }}
            >
              <Typography
                sx={{ textAlign: "center", my: 1, py: 1 }}
                variant="h6"
              >
                {t("noPost")}
              </Typography>
            </Paper>
          )}
        {(postsStatus === "loading" || eventsStatus === "loading") && (
          <Paper
            elevation={2}
            sx={{
              padding: "1rem",
              my: 1,
              borderRadius: 2,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ my: 1 }}
              />
              <Box sx={{ display: "flex", flexFlow: "column" }}>
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ mt: 1, mx: 1 }}
                />
                <Skeleton
                  variant="text"
                  width={100}
                  height={10}
                  sx={{ mx: 1 }}
                />
              </Box>
            </Box>
            <Skeleton variant="text" height={10} sx={{ my: 0.5 }} />
            <Skeleton variant="text" width="75%" height={10} sx={{ my: 0.5 }} />
            <Skeleton variant="rectangular" height={300} sx={{ my: 1 }} />
            <Box
              sx={{ mt: 0.5, display: "flex", justifyContent: "space-between" }}
            >
              <Skeleton variant="rounded" width="32%" height={20} />
              <Skeleton variant="rounded" width="32%" height={20} />
              <Skeleton variant="rounded" width="32%" height={20} />
            </Box>
          </Paper>
        )}
      </Box>
      <AlertElem
        isAlertMessage={isAlertMessage}
        alertMessage={alertMessage}
        alertMessageType={alertMessageType}
        onClose={() =>
          dispatch(setAlertMessage({ show: false, message: "", type: "" }))
        }
      />
      {profile?._id && (
        <PostCreationModal
          oldPost={editingPost}
          editPostFunction={editFeedPost}
          setEditingPost={setEditingPost}
          profile={profile}
          newPostModalOpened={newPostModalOpened}
          setNewPostModalOpened={setNewPostModalOpened}
        />
      )}
      {profile?._id && (
        <EventCreationModal
          oldEvent={editingEvent}
          editEventFunction={editFeedEvent}
          setEditingEvent={setEditingEvent}
          profile={profile}
          newEventModalOpened={newEventModalOpened}
          setNewEventModalOpened={setNewEventModalOpened}
        />
      )}
    </Box>
  );
}
