import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import i18n from "../i18n";
import Utils from "../utils/Utils";

const { serverURL } = Utils;

const initialState = {
  postsStatus: "idle",
  eventsStatus: "idle",
  feedPosts: [],
  feedEvents: [],
  hasMorePosts: true,
  hasMoreEvents: true,
  isAlertMessage: false,
  alertMessage: "",
  alertMessageType: "",
};

export const fetchPosts = createAsyncThunk(
  "feeds/fetchPosts",
  async (filter) => {
    const response = await fetch(`${serverURL}/feeds/posts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter }),
    });
    if (!response.ok) throw new Error(await response.text());
    const posts = await response.json();
    return posts.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const editFeedPost = createAsyncThunk(
  "feeds/editFeedPost",
  async (post) => {
    const response = await fetch(`${serverURL}/post/edit`, {
      method: "POST",
      credentials: "include",
      body: post,
    });
    if (!response.ok) throw new Error(await response.text());
    const editedPost = await response.json();
    const loc = editedPost.location;
    editedPost.location = { lat: loc.coordinates[1], lng: loc.coordinates[0] };
    return editedPost;
  }
);
export const deleteFeedPost = createAsyncThunk(
  "feeds/deleteFeedPost",
  async (id) => {
    const response = await fetch(`${serverURL}/post/delete`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) throw new Error(await response.text());
    return id;
  }
);
export const fetchMorePosts = createAsyncThunk(
  "feeds/fetchMorePosts",
  async (filter, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await fetch(`${serverURL}/feeds/posts`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter, skip: state.feeds.feedPosts.length }),
    });
    if (!response.ok) throw new Error(await response.text());
    const posts = await response.json();
    return posts.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);

export const fetchEvents = createAsyncThunk(
  "feeds/fetchEvents",
  async (filter) => {
    const response = await fetch(`${serverURL}/feeds/events`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter }),
    });
    if (!response.ok) throw new Error(await response.text());
    const events = await response.json();
    return events.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);

export const editFeedEvent = createAsyncThunk(
  "feeds/editFeedEvent",
  async (event) => {
    const response = await fetch(`${serverURL}/event/edit`, {
      method: "POST",
      credentials: "include",
      body: event,
    });
    if (!response.ok) throw new Error(await response.text());
    const editedEvent = await response.json();
    const loc = editedEvent.location;
    editedEvent.location = { lat: loc.coordinates[1], lng: loc.coordinates[0] };
    return editedEvent;
  }
);
export const deleteFeedEvent = createAsyncThunk(
  "feeds/deleteFeedEvent",
  async (id) => {
    const response = await fetch(`${serverURL}/event/delete`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) throw new Error(await response.text());
    return id;
  }
);
export const fetchMoreEvents = createAsyncThunk(
  "feeds/fetchMoreEvents",
  async (filter, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await fetch(`${serverURL}/feeds/events`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filter, skip: state.feeds.feedEvents.length }),
    });
    if (!response.ok) throw new Error(await response.text());
    const events = await response.json();
    return events.map((event) => {
      const loc = event.location;
      return {
        ...event,
        location: { lat: loc.coordinates[1], lng: loc.coordinates[0] },
      };
    });
  }
);
export const reportPost = createAsyncThunk(
  "feeds/reportPost",
  async (report) => {
    const response = await fetch(`${serverURL}/post/report`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(report),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const reportDetails = await response.json();
    return reportDetails;
  }
);
export const reportEvent = createAsyncThunk(
  "feeds/reportEvent",
  async (report) => {
    const response = await fetch(`${serverURL}/event/report`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(report),
      credentials: "include",
    });
    if (!response.ok) throw new Error(await response.text());
    const reportDetails = await response.json();
    return reportDetails;
  }
);

export const feedsSlice = createSlice({
  name: "feeds",
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
      state.alertMessage = action.payload.message;
      state.alertMessageType = action.payload.type;
      state.isAlertMessage = action.payload.show;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.postsStatus = "loading";
    });
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.feedPosts = action.payload;
      state.postsStatus = "success";
    });
    builder.addCase(fetchPosts.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchPosts")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.postsStatus = "failed";
    });
    builder.addCase(editFeedPost.fulfilled, (state, action) => {
      const editedPost = action.payload;
      const indexInPosts = state.feedPosts.findIndex(
        (post) => post._id === editedPost._id
      );
      if (indexInPosts !== -1) {
        state.feedPosts[indexInPosts] = editedPost;
      }
      toast.success(i18n.t("updateSuccessful"));
    });
    builder.addCase(editFeedPost.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToEditPost")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(deleteFeedPost.fulfilled, (state, action) => {
      state.feedPosts = state.feedPosts.filter((p) => p._id !== action.payload);
    });
    builder.addCase(deleteFeedPost.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToDeletePost")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(fetchMorePosts.pending, (state) => {
      state.postsStatus = "loading";
    });
    builder.addCase(fetchMorePosts.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.hasMorePosts = false;
        state.postsStatus = "success";
        return;
      }
      state.feedPosts = [...state.feedPosts, ...action.payload];
      state.postsStatus = "success";
    });
    builder.addCase(fetchMorePosts.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchMorePosts")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.postsStatus = "failed";
    });

    builder.addCase(fetchEvents.pending, (state) => {
      state.eventsStatus = "loading";
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.feedEvents = action.payload;
      state.eventsStatus = "success";
    });
    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchEvents")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.eventsStatus = "failed";
    });
    builder.addCase(editFeedEvent.fulfilled, (state, action) => {
      const editedEvent = action.payload;
      const index = state.feedEvents.findIndex(
        (post) => post._id === editedEvent._id
      );
      if (index !== -1) {
        state.feedEvents[index] = editedEvent;
      }
      toast.success(i18n.t("updateSuccessful"));
    });
    builder.addCase(editFeedEvent.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToEditEvent")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(deleteFeedEvent.fulfilled, (state, action) => {
      state.feedEvents = state.feedEvents.filter(
        (p) => p._id !== action.payload
      );
    });
    builder.addCase(deleteFeedEvent.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToDeleteEvent")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
    });
    builder.addCase(fetchMoreEvents.pending, (state) => {
      state.eventsStatus = "loading";
    });
    builder.addCase(fetchMoreEvents.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.hasMoreEvents = false;
        state.eventsStatus = "success";
        return;
      }
      state.feedEvents = [...state.feedEvents, ...action.payload];
      state.eventsStatus = "success";
    });
    builder.addCase(fetchMoreEvents.rejected, (state, action) => {
      state.alertMessage = `${i18n.t("failedToFetchMoreEvents")} ${
        action.error.message
      }`;
      state.alertMessageType = "error";
      state.isAlertMessage = true;
      state.eventsStatus = "failed";
    });
    builder.addCase(reportPost.fulfilled, (state) => {
      state.isAlertMessage = true;
      state.alertMessage = i18n.t("successfullyReported");
      state.alertMessageType = "success";
    });
    builder.addCase(reportPost.rejected, (state, action) => {
      state.isAlertMessage = true;
      state.alertMessage = `${i18n.t("reportFailed")} ${action.error.message}`;
      state.alertMessageType = "error";
    });
    builder.addCase(reportEvent.fulfilled, (state) => {
      state.isAlertMessage = true;
      state.alertMessage = i18n.t("successfullyReported");
      state.alertMessageType = "success";
    });
    builder.addCase(reportEvent.rejected, (state, action) => {
      state.isAlertMessage = true;
      state.alertMessage = `${i18n.t("reportFailed")} ${action.error.message}`;
      state.alertMessageType = "error";
    });
  },
});

export default feedsSlice.reducer;
export const { setAlertMessage } = feedsSlice.actions;
