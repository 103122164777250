import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export default function AuthOutlet() {
    const location = useLocation();
    const { status, profile, isLoggedIn } = useSelector((state) => state.auth);
    const { from } = location.state || { from: { pathname: '/' } };
    if (status === 'success' && isLoggedIn) {
        if (!profile?.yearOfBirth) return <Navigate to={{ pathname: '/profile' }} />;
        return <Navigate to={from} />;
    }
    return <Outlet />;
}
