import { Alert, Snackbar } from "@mui/material";
import Slide from "@mui/material/Slide";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function AlertElem({
  isAlertMessage,
  alertMessage,
  alertMessageType,
  onClose,
}) {
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    onClose();
  };
  
  return (
    isAlertMessage && (
      <Snackbar
        open={isAlertMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={alertMessageType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    )
  );
}
