import { Box } from "@mui/material";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

const defaultZoom = 10;
let map;

function Location({ location, setLocation }) {
  const { t } = useTranslation(["common"]);
  const [zoom, setZoom] = useState(defaultZoom);
  const [center, setCenter] = useState({ ...location });

  const updateDefaultLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          if (map) map.panTo(coords);
          setCenter(coords);
          setLocation(coords);
        },
        (error) => {
          console.error("Error getting location.", error);
          if (isNaN(location.lat) || isNaN(location.lng)) {
            const coords = {
              lat: 48.8584,
              lng: 2.2945,
            };
            setCenter(coords);
            setLocation(coords);
            if (map) map.panTo(location);
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [setCenter, setLocation, location]);

  // set location if not set in database
  useEffect(() => {
    if (isNaN(center?.lat) || isNaN(center?.lng)) {
      updateDefaultLocation();
    }
  }, [updateDefaultLocation, center]);

  // add buttons to the map
  const handleMapLoad = (m) => {
    map = m;
    map.addListener("zoom_changed", () => {
      setZoom(map.getZoom());
    });
    // button to get location
    const locateButton = document.createElement("button");
    const locateIcon = document.createElement("img");
    locateButton.type = "button";
    locateIcon.src =
      "https://img.icons8.com/color-glass/48/google-maps-new.png";
    locateIcon.alt = t("locate");
    locateButton.appendChild(locateIcon);
    locateButton.title = t("addCurrentLocation");
    locateButton.classList.add("locate-button");
    locateButton.addEventListener("click", updateDefaultLocation);
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      locateButton
    );
  };

  return (
    <Box my={1}>
      {center?.lat && center?.lng ? (
        <GoogleMap
          onLoad={handleMapLoad}
          mapContainerClassName="map-container"
          center={center}
          zoom={zoom}
        >
          {location?.lat && (
            <MarkerF
              position={location}
              draggable
              icon={{
                url: "location-pin.png",
                scaledSize: new window.google.maps.Size(35, 35),
              }}
              onLoad={(marker) => {
                marker.addListener("position_changed", () => {
                  const c = marker.getPosition();
                  const coords = { lat: c.lat(), lng: c.lng() };
                  setLocation((prevCenter) => {
                    if (
                      coords.lat !== prevCenter.lat ||
                      coords.lng !== prevCenter.lng
                    ) {
                      return coords;
                    }
                    return prevCenter;
                  });
                });
              }}
            />
          )}
        </GoogleMap>
      ) : (
        <Loader />
      )}
    </Box>
  );
}

export default Location;
