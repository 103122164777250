function NodeTree({ nodes }) {
  return (
    <ul>
      {nodes.map((item) => (
        <li key={item._id}>
          {item.name}
          {item.children.length > 0 && <NodeTree nodes={item.children} />}
        </li>
      ))}
    </ul>
  );
}

export default NodeTree;
