import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

export default function Countdown({ targetTime, onRedirect }) {
    const [secondsRemaining, setSecondsRemaining] = useState(targetTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSecondsRemaining((prevSeconds) => Math.max(0, prevSeconds - 1));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const progress = Math.min(1, secondsRemaining / targetTime);
    if (secondsRemaining === 0) {
        onRedirect(); // Trigger redirect on countdown completion
    }

    return (
        <Box className="relative inline-flex justify-center">
            <CircularProgress variant="determinate" value={progress * 100} size={60} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h6" component="div" color="text.secondary">
                    {secondsRemaining}
                </Typography>
            </Box>
        </Box>
    );
}
